import React, { useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import "./Navb.css";
// import { Link } from "react-router-dom";
// import ScrollAnimation from "react-animate-on-scroll";
// import axios from "axios";

const Navb = (props) => {
  // const clrArray = ["#80d78f", "#657dc5", "#392532", "#6c3e5f"];
  // let countToggler = 0;
  // const [navTogBgClr, setNavTogBgClr] = useState("#000");
  const imagePath = process.env.PUBLIC_URL + "/assets/images/";

  useEffect(() => {
    document.body.style.backgroundColor = "#f4f2fb";
    document.body.style.fontFamily = "Jost";
  });

  function hoverEffectIn(e) {
    e.target.style.color = "#9533be";
  }

  function hoverEffectOut(e) {
    e.target.style.color = "#000";
    e.target.style.fontWeight = "normal";
  }

  // function handleToggler() {
  //   ++countToggler;
  //   if (countToggler % 2 === 0) {
  //     setNavTogBgClr(clrArray[Math.floor(Math.random() * clrArray.length)]);
  //   }
  // }

  return (
    <Navbar
      // fixed="top"
      expand="lg"
      // bd="dark"
      style={{ marginTop: "20px" }}
    >
      <Navbar.Brand className="nav-brand" href="/">
        <img
          src={`${imagePath}logo-with-title.png`}
          alt=""
          width="130px"
          height="80px"
        />
      </Navbar.Brand>
      <Navbar.Toggle
        // onClick={handleToggler}
        aria-controls="basic-navbar-nav"
        style={{
          backgroundColor: "trasparent",
          borderColor: "transparent",
          color: "#000",
          marginRight: "10px",
        }}
      >
        <span style={{ backgroundColor: "#9533be" }}></span>
        <span style={{ backgroundColor: "#9533be" }}></span>
        <span style={{ backgroundColor: "#9533be" }}></span>
      </Navbar.Toggle>
      <Navbar.Collapse
        id="basic-navbar-nav"
        style={{
          marginTop: "10px",
          width: "100%",
          marginLeft: "auto",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <Nav className="main-nav" style={{ display: props.dis }}>
          <Nav.Link
            className="intro"
            onMouseOver={hoverEffectIn}
            onMouseLeave={hoverEffectOut}
            style={{
              color: "#000",
            }}
            href="#intro"
          >
            Let's Do This
          </Nav.Link>
          <Nav.Link
            onMouseOver={hoverEffectIn}
            onMouseLeave={hoverEffectOut}
            style={{
              color: "#000",
              // paddingRight: "50px",
            }}
            href="#what-we-deal-in"
            className="what-we-deal-in"
          >
            What We Deal In
          </Nav.Link>
          <Nav.Link
            onMouseOver={hoverEffectIn}
            onMouseLeave={hoverEffectOut}
            style={{
              color: "#000",
            }}
            href="#who-we-are"
            className="who-we-are"
          >
            Who We Are
          </Nav.Link>
          <Nav.Link
            onMouseOver={hoverEffectIn}
            onMouseLeave={hoverEffectOut}
            style={{
              color: "#000",
            }}
            href="#about-us"
            className="why-us"
          >
            Why Us?
          </Nav.Link>
          <Nav.Link
            onMouseOver={hoverEffectIn}
            onMouseLeave={hoverEffectOut}
            style={{
              color: "#000",
            }}
            href="/portfolio"
            className="portfolio"
          >
            Portfolio
          </Nav.Link>
        </Nav>
        {/* <hr style={{ backgroundColor: "#000" }} /> */}
        <Nav className="nav-second" style={{ display: props.dis }}>
          <a href="#contact-us">
            {" "}
            <button className="contact-button">Contact Us</button>
          </a>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navb;

import React from "react";
import "./Writing.css";

const Des = () => {
  return (
    <div>
      <svg
        className="w-class"
        viewBox="0 0 879 702"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="undraw_content_creator_xeju 1" clipPath="url(#clip0)">
          <path
            id="filled-square"
            d="M229 97.5H43V248.5H229V97.5Z"
            fill="#5B1B76"
          />
          <path
            id="empty-square"
            d="M216 88.5H30V239.5H216V88.5Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <g id="lines">
            <path
              id="Vector"
              d="M558 294.5H43V310.5H558V294.5Z"
              fill="#F2F2F2"
            />
            <path id="Vector_2" d="M483 139H303V155H483V139Z" fill="#F2F2F2" />
            <path id="Vector_3" d="M558 182H303V198H558V182Z" fill="#F2F2F2" />
            <path
              id="Vector_4"
              d="M470 130H290V146H470V130Z"
              stroke="#3F3D56"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_5"
              d="M545 173H290V189H545V173Z"
              stroke="#3F3D56"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_6"
              d="M545 285.5H30V301.5H545V285.5Z"
              stroke="#3F3D56"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_7"
              d="M558 344.5H43V360.5H558V344.5Z"
              fill="#F2F2F2"
            />
            <path
              id="Vector_8"
              d="M545 335.5H30V351.5H545V335.5Z"
              stroke="#3F3D56"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_9"
              d="M558 394.5H43V410.5H558V394.5Z"
              fill="#F2F2F2"
            />
            <path
              id="Vector_10"
              d="M545 385.5H30V401.5H545V385.5Z"
              stroke="#3F3D56"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_11"
              d="M558 444.5H43V460.5H558V444.5Z"
              fill="#F2F2F2"
            />
            <path
              id="Vector_12"
              d="M545 435.5H30V451.5H545V435.5Z"
              stroke="#3F3D56"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_13"
              d="M558 494.5H43V510.5H558V494.5Z"
              fill="#F2F2F2"
            />
            <path
              id="Vector_14"
              d="M545 485.5H30V501.5H545V485.5Z"
              stroke="#3F3D56"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_15"
              d="M558 544.5H43V560.5H558V544.5Z"
              fill="#F2F2F2"
            />
            <path
              id="Vector_16"
              d="M545 535.5H30V551.5H545V535.5Z"
              stroke="#3F3D56"
              strokeMiterlimit="10"
            />
          </g>
          <path
            id="Vector_17"
            d="M587.5 0.5H0.5V648.5H587.5V0.5Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            id="Vector_18"
            d="M588 235C588 235 600.5 261.5 598.5 269.5C597.329 274.363 597.859 279.48 600 284L623 283L644.5 262.5C644.5 262.5 612 240 614 229C616 218 588 235 588 235Z"
            fill="#A0616A"
          />
          <path
            id="Vector_19"
            d="M646 277L656 339C656 339 656 395 653 396C650 397 637 396 637 396V333L641 278L646 277Z"
            fill="#5B1B76"
          />
          <path
            id="Vector_20"
            opacity="0.1"
            d="M646 277L656 339C656 339 656 395 653 396C650 397 637 396 637 396V333L641 278L646 277Z"
            fill="black"
          />
          <path
            id="Vector_21"
            d="M706 641L714 672L726 663L737 638L723 621L706 641Z"
            fill="#A0616A"
          />
          <path
            id="Vector_22"
            d="M737 634C737 634 724 644 724 654C724 654 717 665 709 664V685C709 685 684 698 703 701C722 704 736 680 736 680C736 680 754 651 751 647C748 643 737 634 737 634Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_23"
            d="M645.926 391.273C645.926 391.273 665 412 656 444C656 444 646 516 678 543C710 570 731 629 731 629L707 651C707 651 681 592 654 569C627 546 624 520 624 520V429L619 391L645.926 391.273Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_24"
            opacity="0.1"
            d="M645.926 391.273C645.926 391.273 665 412 656 444C656 444 646 516 678 543C710 570 731 629 731 629L707 651C707 651 681 592 654 569C627 546 624 520 624 520V429L619 391L645.926 391.273Z"
            fill="black"
          />
          <path
            id="Vector_25"
            d="M621 664L611 675L615 684L627 688L648 680L645 668L621 664Z"
            fill="#A0616A"
          />
          <path
            id="Vector_26"
            d="M651 675C651 675 627 687 621 682C619.267 680.614 617.84 678.884 616.81 676.918C615.78 674.953 615.17 672.795 615.017 670.581C615.017 670.581 603 672 595 677C587 682 581 688 571 688C561 688 565 700 584 701C603 702 651 700 651 695V675Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_27"
            d="M583 404C583 404 570 462 593 528C593 528 604 565 609 576C614 587 619 665 617 668C617 668 633 681 647 672C647 672 646 626 648 622C650 618 652 583 640 554C628 525 638 455 638 455C638 455 662 419 644 398L583 404Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_28"
            d="M598 242C613.464 242 626 229.464 626 214C626 198.536 613.464 186 598 186C582.536 186 570 198.536 570 214C570 229.464 582.536 242 598 242Z"
            fill="#A0616A"
          />
          <path
            id="Vector_29"
            d="M650 397C650 397 642 409 636 403C631.77 398.77 604.69 407.96 589.07 413.75C582.53 416.17 578 418 578 418C578 418 577.57 404.14 576.3 386.59C574.84 366.45 572.28 341.43 568 327C560 300 580 289 580 289C580 289 597 269 596 266C595 263 641 248 641 248C646 249 650 294 650 294C650 294 641 330 636 352C631 374 650 397 650 397Z"
            fill="#5B1B76"
          />
          <path
            id="Vector_30"
            d="M568.157 197.278C565.599 197.506 564.137 200.727 564.963 203.159C565.789 205.59 568.161 207.181 570.585 208.031C573.008 208.881 575.599 209.172 578.025 210.015C580.438 210.897 582.676 212.197 584.639 213.855C591.551 219.516 595.644 228.141 596.946 236.98C598.249 245.819 596.951 254.876 594.494 263.466C593.192 268.016 591.067 273.003 586.619 274.62C591.481 273.419 596.232 271.808 600.821 269.804C604.185 268.22 607.341 266.215 610.741 264.71C615.773 262.657 621.053 261.272 626.445 260.592C630.231 260.002 634.233 259.553 637.788 260.981C639.968 261.995 641.975 263.348 643.732 264.989L655.167 274.597C655.366 270.42 655.498 266.24 655.562 262.058C655.617 258.423 655.603 254.683 654.264 251.303C652.941 247.967 650.43 245.247 648.749 242.075C644.592 234.228 645.946 224.775 646.024 215.895C646.104 206.7 644.624 197.33 640.366 189.18C636.109 181.03 628.841 174.205 619.976 171.765C608.027 168.476 595.431 173.371 584.447 179.112C581.863 180.342 579.454 181.91 577.283 183.776C574.976 186.134 573.015 188.807 571.459 191.717L566.021 200.829"
            fill="#3F3D56"
          />
          <path
            id="Vector_31"
            opacity="0.1"
            d="M639 304C639 304 620 376 618 381C616.56 384.59 599.62 403.69 589.07 413.75C582.53 416.17 578 418 578 418C578 418 577.57 404.14 576.3 386.59C584.44 377.56 593 369 593 369C593 369 602 314 601 293C600 272 621 267 621 267C645 270 639 304 639 304Z"
            fill="black"
          />
          <g id="hand">
            <path
              id="Vector_32"
              d="M513.906 402.51C513.906 402.51 483.425 408.746 470.939 407.692C458.454 406.637 442.426 420.021 447.733 428.318C453.039 436.615 484.055 425.939 484.055 425.939L521.48 421.891L513.906 402.51Z"
              fill="#A0616A"
            />
            <path
              id="Vector_33"
              d="M633.357 319.609C633.357 319.609 613.077 312.21 602.34 330.285C591.603 348.36 553.787 389.298 553.787 389.298C553.787 389.298 506.679 398.936 506.979 403.927C507.278 408.918 513.671 422.563 515.647 425.148C517.623 427.732 563.613 416.158 568.037 413.088C572.461 410.017 627.99 360.403 627.99 360.403C627.99 360.403 651.731 335.337 633.357 319.609Z"
              fill="#5B1B76"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="878.5" height="701.26" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Des;

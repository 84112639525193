import React from "react";
import "./Think.css";

const Think = () => {
  return (
    <div>
      <svg
        className="think-class"
        viewBox="0 0 152 197"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Vector"
          d="M28.7506 170.51L19.5791 169.774L22.6363 186.324L33.0306 181.176L28.7506 170.51Z"
          fill="#FFB9B9"
        />
        <path
          id="Vector_2"
          d="M112.823 139.615L91.4232 114.605L79.1941 126.007L55.0424 160.947L57.7939 166.096L87.1428 142.19L107.015 173.452L125.969 162.05L112.823 139.615Z"
          fill="#2F2E41"
        />
        <path
          id="Vector_3"
          opacity="0.1"
          d="M112.823 139.615L91.4232 114.605L79.1941 126.007L55.0424 160.947L57.7939 166.096L87.1428 142.19L107.015 173.452L125.969 162.05L112.823 139.615Z"
          fill="black"
        />
        <path
          id="Vector_4"
          d="M114.658 156.166L111.906 158.74L89.5888 149.546L84.3915 146.971L73.9971 149.546C73.9971 149.546 73.9971 139.247 83.7801 139.615L93.8688 142.558L105.792 143.661L114.658 156.166Z"
          fill="#FFB9B9"
        />
        <path
          id="Vector_5"
          d="M134.53 183.382L127.804 188.531H119.855L82.8629 171.981L28.7506 183.75L27.2219 169.774L76.7485 143.661L120.161 160.947L134.53 183.382Z"
          fill="#2F2E41"
        />
        <path
          id="hand"
          d="M91.1173 117.916L86.5315 119.019L85.5322 117.617C84.7411 116.506 84.2399 115.14 84.0913 113.688C83.9428 112.237 84.1536 110.765 84.6972 109.457V109.457L97.5374 89.2282C97.5374 89.2282 101.206 79.2979 107.015 83.3436C112.823 87.3893 102.123 94.0095 102.123 94.0095L95.7031 106.147L96.3146 112.399L91.1173 117.916Z"
          fill="#FFB9B9"
        />
        <path
          id="Vector_6"
          d="M114.811 90.1477L120.314 105.963L133.46 99.3424C133.46 99.3424 129.179 86.8376 129.179 86.4698C129.179 86.102 114.811 90.1477 114.811 90.1477Z"
          fill="#FFB9B9"
        />
        <path
          id="Vector_7"
          d="M151.011 136.934C151.011 145.591 149.877 154.193 147.651 162.426L146.758 165.728L142.478 189.267L137.892 190.738L134.53 185.589L127.804 179.704L122.912 170.142L118.718 161.734L117.409 159.108L112.823 131.524L95.3974 119.755L89.5887 118.651L90.8116 114.606L96.0089 106.147L101.206 105.411L102.735 103.94L103.958 104.675L108.849 101.365L110.378 102.101L119.317 103.101L124.746 101.733L132.555 96.6906L135.752 97.3196L146.758 108.353C149.572 117.502 151.012 127.179 151.011 136.934V136.934Z"
          fill="#575A89"
        />
        <path
          id="shoes"
          d="M22.3944 176.205C22.3944 176.205 25.1326 170.621 23.976 169.97C22.8194 169.318 17.9077 166.719 17.9077 166.719L10.7046 163.848C10.7046 163.848 0.311054 145.264 0.0829142 161.425C-0.145226 177.585 7.6507 181.469 7.6507 181.469C7.6507 181.469 14.6037 199.471 18.5423 196.6C22.4809 193.73 26.422 183.985 26.422 183.985C26.422 183.985 19.3195 179.392 22.3944 176.205Z"
          fill="#2F2E41"
        />
        <path
          id="Vector_8"
          opacity="0.1"
          d="M147.675 133.731L136.058 142.925L118.718 161.734L117.409 159.108L113.741 137.041H114.658L124.135 116.077L147.675 133.731Z"
          fill="black"
        />
        <path
          id="Vector_9"
          d="M124.135 113.87L114.658 134.834H111.295L109.155 142.19L104.263 143.661L117.409 160.947L136.058 140.719L147.675 131.524L124.135 113.87Z"
          fill="#575A89"
        />
        <g id="face">
          <path
            id="Vector_10"
            d="M120.161 94.0095C126.746 94.0095 132.084 87.5876 132.084 79.6657C132.084 71.7439 126.746 65.322 120.161 65.322C113.576 65.322 108.238 71.7439 108.238 79.6657C108.238 87.5876 113.576 94.0095 120.161 94.0095Z"
            fill="#FFB9B9"
          />
          <path
            id="Vector_11"
            d="M110.887 69.0005L109.595 69.5984C109.396 69.4366 109.227 69.2286 109.096 68.9868C108.964 68.745 108.875 68.4742 108.832 68.1901C108.789 67.9061 108.794 67.6146 108.845 67.3328C108.897 67.0509 108.995 66.7844 109.134 66.5487L106.747 66.4143C108.279 63.9589 110.228 61.923 112.463 60.4413C114.699 58.9595 117.17 58.0658 119.714 57.8191C122.258 57.5724 124.817 57.9784 127.221 59.0103C129.624 60.0422 131.819 61.6764 133.658 63.8048C133.767 63.3545 134.952 64.2306 135.436 65.1191C135.598 64.3862 136.709 67.9287 137.102 70.4327C137.284 69.5989 137.983 70.9449 137.371 72.2364C137.759 72.1681 137.934 73.3632 137.634 74.0289C138.058 73.7889 137.987 75.2154 137.526 76.1687C138.132 76.1039 132.746 86.1606 130.565 92.0378C129.932 88.5376 129.22 84.8518 127.178 82.2445C126.831 81.8013 126.451 81.3976 126.072 80.9947L124.019 78.8153C121.633 76.2811 119.462 73.1534 116.283 72.2063C114.098 71.5554 112.717 71.4084 113.907 68.4467C112.832 68.9868 111.827 69.7899 110.742 70.2915C110.756 69.8776 110.908 69.4145 110.887 69.0005Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_12"
            d="M126.122 83.8953C127.304 83.8953 128.262 82.7426 128.262 81.3208C128.262 79.8989 127.304 78.7462 126.122 78.7462C124.94 78.7462 123.982 79.8989 123.982 81.3208C123.982 82.7426 124.94 83.8953 126.122 83.8953Z"
            fill="#FFB9B9"
          />
        </g>
        <g id="right-think">
          <path
            id="Vector_13"
            d="M76.3646 23.4553H66.0176V35.903H76.3646V23.4553Z"
            fill="#5B1B76"
          />
          <path
            id="Vector_14"
            d="M82.6467 28.79H70.0825V13.6749H82.6467V28.79ZM70.6288 28.1328H82.1004V14.3321H70.6288V28.1328Z"
            fill="#3F3D56"
          />
        </g>
        <g id="bottom-think">
          <path
            id="Vector_15"
            d="M30.1338 73.8974L27.2927 61.9281L17.3434 65.346L20.1844 77.3153L30.1338 73.8974Z"
            fill="#5B1B76"
          />
          <path
            id="Vector_16"
            d="M27.5947 82.6292L24.1448 68.0951L36.2262 63.9448L39.676 78.479L27.5947 82.6292ZM24.8201 68.5466L27.97 81.8169L39.0008 78.0275L35.8509 64.7572L24.8201 68.5466Z"
            fill="#3F3D56"
          />
        </g>
        <g id="left-think">
          <path
            id="Vector_17"
            d="M27.5623 18.1502L34.6273 9.05585L27.0677 0.556539L20.0027 9.65092L27.5623 18.1502Z"
            fill="#5B1B76"
          />
          <path
            id="Vector_18"
            d="M20.0331 20.0293L28.612 8.98608L37.7915 19.3067L29.2126 30.3498L20.0331 20.0293ZM28.6381 9.91495L20.8052 19.9979L29.1865 29.421L37.0194 19.3381L28.6381 9.91495Z"
            fill="#3F3D56"
          />
        </g>
      </svg>
    </div>
  );
};

export default Think;

import React from "react";
// import Data from "../SVGs/Data";
import Des from "../SVGs/Des";
// import TechDev from "../SVGs/TechDev";
// import ScrollAnimation from "react-animate-on-scroll";
import "./Design.css";

const ProductDesign = () => {
  const svgPath = process.env.PUBLIC_URL + "/assets/svgs/";

  return (
    <div>
      {/* <span>Technology & Development</span>
                <span>Designing</span>
                <span>Machine Learning</span>
                <span>Artificial Intelligene</span>
                <span>Writing</span> */}
      <div className="list-header"></div>
      <div
        style={{
          // border: "1px solid #b7acd8",
          borderRadius: "32px",
          padding: "20px",
          // backgroundColor: "#b7acd8",
        }}
      >
        <div className="main-div">
          <div className="heading animate__animated animate__fadeIn animate__slow">
            <Des />
            <h5 style={{ fontWeight: "bold", color: "#5b1b76" }}>
              Designing Your Ideas
            </h5>
          </div>
          <span style={{ width: "40px", height: "20px" }}></span>
          <hr className="resp-hr" />
          <hr className="hr-hor" />
          <div className="table-of-tech animate__animated  animate__zoomIn">
            {/* <ScrollAnimation
              delay={700}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}photoshop-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Photoshop</p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={600}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img alt="" className="image-hw" src={`${svgPath}xd-icon.svg`} />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Xd</p>
            </div>
            {/* </ScrollAnimation> */}
            {/* <div className="padding-for-cards">
              <img alt=""
                className="image-hw"
                src={`${svgPath}illustrator-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Illustrator</p>
            </div> */}
            {/* <ScrollAnimation
              delay={500}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}lightroom-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Lightroom</p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={400}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}premier-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Premier Pro</p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={300}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}aftereffects-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>
                After Effects
              </p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={200}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}indesign-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>InDesign</p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={100}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}figma-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Figma</p>
            </div>
            {/* </ScrollAnimation> */}
            {/* <div className="padding-for-cards">
              <img alt=""
                className="image-hw"
                src={`${svgPath}node-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Node.js</p>
            </div>
            <div className="padding-for-cards">
              <img alt=""
                className="image-hw"
                src={`${svgPath}Django-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Django</p>
            </div>
            <div className="padding-for-cards">
              <img alt=""
                className="image-hw"
                src={`${svgPath}java-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>JAVA</p>
            </div>
            <div className="padding-for-cards">
              <img alt=""
                className="image-hw"
                src={`${svgPath}python-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Python</p>
            </div>
            <div className="padding-for-cards">
              <img alt=""
                className="image-hw"
                src={`${svgPath}mongo-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>MongoDB</p>
            </div>
            <div className="padding-for-cards">
              <img alt=""
                className="image-hw"
                src={`${svgPath}sql-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>MySQL</p>
            </div>
            <div className="padding-for-cards">
              <img alt=""
                className="image-hw"
                src={`${svgPath}postgre-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>PostgreSQL</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDesign;

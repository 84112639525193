import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import FooterCards from "../Musts/FooterCards";
import FooterBottom from "../Musts/FooterBottom";
import "./FooterLanding.css";

const FooterLanding = () => {
  return (
    <div
      id="about-us"
      style={{
        // margin: "auto",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "200px",
        color: "#fff",
      }}
    >
      <div
        className="for-width"
        style={{ marginLeft: "auto", marginRight: "auto" }}
      >
        {" "}
        <h3
          style={{ color: "#9533be", textAlign: "center", fontWeight: "bold" }}
        >
          Why SenaryBits
        </h3>
        <div style={{ textAlign: "center" }} className="container">
          <p
            style={{
              color: "#686868",
              fontSize: "15px",
              margintop: "5px",
              fontWeight: 500,
            }}
          >
            Okay, okay! We know we made you read a lot, let's just end this with
            our motives.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          // margin: "auto",
          marginTop: "20px",
          marginLeft: "auto",
          marginRight: "auto",
          justifyContent: "space-between",
          display: "flex",
          flexWrap: "wrap",
          // padding: "80px",
          // paddingBottom: "200px",
        }}
      >
        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <FooterCards
            imageName="goals"
            width="70px"
            height="50px"
            mainDivName="know-more"
            mainImage="know-image"
            headName="know-head"
            headColor="#80d78f"
            learnName="know-learn"
            heading="Our Determination"
            description="We take pride in our strong capability for designing and engineering robust software using mobile & web technologies. Our goal is to be concise."
          />
          <br />
          <span style={{ width: "50px", height: "5px" }}></span>
          {/* <br /> */}
        </div>

        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <FooterCards
            // style={{ marginTop: "100px" }}
            imageName="building"
            mainDivName="games-library"
            mainImage="gl-image"
            headName="gl-head"
            learnName="know-learn"
            headColor="#657dc5"
            heading="We Value Businesses"
            description="We helped hundreds of businesses expand. We understand the challenges you’re facing and try to bring out the best possible solutions for those challenges."
          />
          <br />
          <span style={{ width: "50px", height: "5px" }}></span>
          {/* <br /> */}
        </div>

        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <FooterCards
            // style={{ marginTop: "100px" }}
            imageName="handshake"
            mainDivName="privacy"
            mainImage="privacy-image"
            headName="privacy-head"
            learnName="privacy-learn"
            headColor="#6c3e5f"
            heading="Promote Transparency"
            description="We promote transparency, we keep loyal relationships to our customers and keep them aware of what we are on. Timely progress updates are part of our development circle."
          />
          <br />
          <span style={{ width: "50px", height: "5px" }}></span>
          {/* <br /> */}
        </div>

        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <FooterCards
            // style={{ marginTop: "100px" }}
            imageName="customer_care"
            mainDivName="bring-your-friends"
            mainImage="byf-image"
            headName="byf-head"
            learnName="byf-learn"
            headColor="#d44638"
            heading="Social Butterfly"
            description="To bring your imagination into reality, we are here to listen you. No matter what, you’re technical or not. Our dedicated team is available to hear from you anytime."
          />
          <br />
          <span style={{ width: "50px", height: "5px" }}></span>
          {/* <br /> */}
        </div>
      </div>
      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
        <FooterBottom />
      </ScrollAnimation>
    </div>
  );
};

export default FooterLanding;

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import * as serviceWorker from "./serviceWorker";
import HttpsRedirect from "react-https-redirect";
// import Main from "./Components/Clients/Frost360/Main";
import PortfolioMain from "./Components/Portfolio/PortfolioMain";
import ErrorPage from "./Components/Musts/ErrorPage";
// import FinalProduct from "./Components/Clients/Frost360/FinalProduct";
// import Preview from "./Components/Clients/Frost360/Preview";
// import AdminPanel from "./Components/AdminPanel/AdminPanel";
// import SignUp from "./Components/LoginandSignUp/SignUp";
// import Login from "./Components/LoginandSignUp/Login";

ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  // <Router history={history}>
  <HttpsRedirect>
    <Router basename="/">
      <Switch>
        <Route path="/" exact render={App} />
        <Route path="/portfolio" exact={true} component={PortfolioMain} />
        <Route path="/error" exact={true} component={ErrorPage} />
        <Redirect to="error" />
        {/* <Route path="/vr-360-demo" component={Main} /> */}
        {/* <Route path="/final-product" component={FinalProduct} /> */}
        {/* <Route path="/preview" component={Preview} /> */}
        {/* <Route path="/adminPanel" exact render={() => <AdminPanel />} />
      <Route path="/signup" component={SignUp} />
      <Route path="/login" component={Login} /> */}
      </Switch>
    </Router>
  </HttpsRedirect>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useState } from "react";
// import ScrollAnimation from "react-animate-on-scroll";
// import Programmers from "../SVGs/Programmers";
import Think from "../SVGs/Think";
import Design from "../SVGs/Design";
import Create from "../SVGs/Create";
import TechList from "./TechList";
import ProductDesign from "./ProductDesign";
import ML from "./ML";
import ContentWriting from "./ContentWriting";
import { Modal, Tabs, Tab } from "react-bootstrap";
import "./WhatWeDeal.css";

const WhatWeDeal = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);

  return (
    <div className="wwd-class" id="what-we-deal-in">
      <div className="upper-div-wwd">
        <h3
          className="title-wwd"
          style={{ color: "#9533be", fontWeight: "bold" }}
        >
          What We Deal In
        </h3>

        <p
          className="descForIntro-wwd"
          style={{
            color: "#686868",
            fontSize: "15px",
            margintop: "5px",
            fontWeight: 500,
          }}
        >
          Throughout the beginning, we have expanded our offerings and
          capitalized on evolving technology trends to better serve our clients.
          <br />
          <br />
          We’ll use whatever technology makes the most sense for a particular
          project. Sometimes this decision is driven by budget, others by
          maintainability or sometimes by the technology you are already using.
          Bring your idea. Let us create it. Our team is well acquainted with
          the most popular technologies:
        </p>
        <br />
      </div>

      <div className="cards-width">
        <div onClick={() => setModalShow(true)} className="we-think">
          <div
            style={{
              color: "#fff",
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <p
              className="card-inner-heading"
              style={{
                color: "#fff",
                fontWeight: "bold",
                fontSize: "22px",
                marginBottom: 0,
              }}
            >
              WE THINK
            </p>
            <p
              className="card-inner-text"
              style={{
                width: "200px",
                fontSize: "12px",
                color: "#F8F8F8",
                fontWeight: 500,
              }}
            >
              What needs to be done. Depending on the size of the tasks...
            </p>
          </div>
          <div
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              // marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Think />
          </div>
        </div>

        <Modal
          // style={{ backgroundColor: "tomato" }}
          show={modalShow}
          onHide={() => setModalShow(false)}
          // size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="modal-top">
            <div className="modal-top-2">
              <div>
                <h4 className="modal-heading">WE THINK</h4>
                <p style={{ fontWeight: 500 }}>
                  What needs to be done. Depending on the size of the tasks and
                  project, it will usually consist of smaller steps, so you can
                  build a proper plan with fewer possibilities of failure.
                </p>
              </div>
              <div className="modal-svg">
                <Think />
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <br />
        <div onClick={() => setModalShow2(true)} className="we-design">
          <div
            style={{
              color: "#fff",
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <p
              className="card-inner-heading"
              style={{
                color: "#fff",
                fontWeight: "bold",
                fontSize: "22px",
                marginBottom: 0,
              }}
            >
              WE DESIGN
            </p>
            <p
              className="card-inner-text"
              style={{
                width: "200px",
                fontSize: "12px",
                color: "#F8F8F8",
                fontWeight: 500,
              }}
            >
              You need to audit your plan’s execution. If something went...
            </p>
          </div>
          <div
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              // marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Design />
          </div>
        </div>

        <Modal
          // style={{ backgroundColor: "tomato" }}
          show={modalShow2}
          onHide={() => setModalShow2(false)}
          // size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="modal-top">
            <div className="modal-top-2">
              <div>
                <h4 className="modal-heading">WE DESIGN</h4>
                <p style={{ fontWeight: 500 }}>
                  You need to audit your plan’s execution. If something went
                  wrong during the process you need to analyze it and find the
                  root cause of the problems. If everything seems perfect and
                  your team managed to achieve the original goals, then you can
                  proceed and apply your initial plan
                </p>
              </div>
              <div className="modal-svg">
                <Design />
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <br />
        <div onClick={() => setModalShow3(true)} className="we-create">
          <div
            style={{
              color: "#fff",
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <p
              className="card-inner-heading"
              style={{
                color: "#fff",
                fontWeight: "bold",
                fontSize: "22px",
                marginBottom: 0,
              }}
            >
              WE CREATE
            </p>
            <p
              className="card-inner-text"
              style={{
                width: "200px",
                fontSize: "12px",
                color: "#F8F8F8",
                fontWeight: 500,
              }}
            >
              Standardization is something that will definitely...
            </p>
          </div>
          <div
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              // marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Create />
          </div>
        </div>

        <Modal
          // style={{ backgroundColor: "tomato" }}
          show={modalShow3}
          onHide={() => setModalShow3(false)}
          // size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="modal-top">
            <div className="modal-top-2">
              <div>
                <h4 className="modal-heading">WE CREATE</h4>
                <p style={{ fontWeight: 500 }}>
                  Standardization is something that will definitely help your
                  team apply the plan smoothly. Make sure that everybody knows
                  their roles and responsibilities.
                </p>
              </div>
              <div className="modal-svg">
                <Create />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <br />
      <br />
      <div className="list-section" style={{ textAlign: "center" }}>
        <p style={{ color: "#9533be", fontWeight: 500 }}>
          ...still want a list? Aaah, here it is
        </p>
        <br />
        <br />
        {/* <br /> */}
        <div style={{}}>
          <Tabs
            className="text-align-center"
            defaultActiveKey="tech"
            id="uncontrolled-tab-example"
          >
            <Tab eventKey="tech" title="Technology & Development">
              {/* <Sonnet /> */}
              <TechList />
            </Tab>
            <Tab eventKey="design" title="Product Design">
              {/* <Sonnet /> */}
              <ProductDesign />
            </Tab>
            <Tab eventKey="ML" title="Data Sciences">
              {/* <Sonnet /> */}
              <ML />
            </Tab>
            <Tab eventKey="writing" title="Writing">
              {/* <Sonnet /> */}
              <ContentWriting />
            </Tab>
          </Tabs>
        </div>
        {/* <br />
        <br />
        <br />
        <br /> */}
        <br />
        <p style={{ color: "#9533be", fontWeight: 500 }}>...and much more :)</p>
        <br />
      </div>
    </div>
  );
};

export default WhatWeDeal;

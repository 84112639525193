import React from "react";
// import Navb from "./Components/Musts/Navb";
// import WhoWeAre from "./Components/LandingPage/WhoWeAre";
// import Intro from "./Components/LandingPage/Intro";
// import WhatWeDeal from "./Components/LandingPage/WhatWeDeal";
// import ScrollAnimation from "react-animate-on-scroll";
import Routes from "./Routes";
import "./App.css";

const App = () => {
  // const imagePath = process.env.PUBLIC_URL + "/assets/images/";

  return <Routes />;
};

export default App;

import React from "react";
import "./Create.css";

const Create = () => {
  return (
    <div>
      <svg
        className="create-class"
        viewBox="0 0 348 550"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Vector"
          d="M108.197 491.523L109.795 503.285C109.795 503.285 150.448 517.181 142.504 523.545C134.561 529.909 70.3278 530.21 68.9899 526.586C67.652 522.963 67.3547 507.29 70.9781 505.952C74.6015 504.614 81.3001 491.161 81.3001 491.161L108.197 491.523Z"
          fill="#2F2E41"
        />
        <path
          id="Vector_2"
          d="M149.216 250.071L123.725 408.481L111.89 502.252L73.653 500.431L86.398 344.753L119.173 256.444L149.216 250.071Z"
          fill="#2F2E41"
        />
        <g id="leg">
          <path
            id="Vector_3"
            d="M67.6287 240.135L86.636 366.592L110.398 515.307L143.951 505.905L129.017 386.137C129.017 386.137 134.517 360.981 131.819 348.003C129.121 335.025 133.19 279.64 133.19 279.64L162.163 258.035L165.497 212.662L94.2374 201.011L67.6287 240.135Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_4"
            d="M144.245 503.001L145.139 514.838C145.139 514.838 184.892 531.133 176.583 537.012C168.274 542.891 104.137 539.361 103.018 535.665C101.898 531.968 102.536 516.305 106.233 515.185C109.929 514.066 117.418 501.036 117.418 501.036L144.245 503.001Z"
            fill="#2F2E41"
          />
        </g>
        <path
          id="Vector_5"
          d="M168.789 65.7159C183.873 65.7159 196.101 53.4879 196.101 38.4039C196.101 23.32 183.873 11.092 168.789 11.092C153.705 11.092 141.477 23.32 141.477 38.4039C141.477 53.4879 153.705 65.7159 168.789 65.7159Z"
          fill="#FFB6B6"
        />
        <path
          id="Vector_6"
          d="M150.582 34.7623L119.628 51.1493L131.463 89.3863H144.209L151.492 80.2823L148.761 74.8203L170.61 58.4323L150.582 34.7623Z"
          fill="#FFB6B6"
        />
        <path
          id="Vector_7"
          d="M140.567 85.7443L140.112 70.7233L127.678 46.8873L80.936 66.1713L97.323 214.566L153.768 218.207L151.947 155.39L161.051 87.1103L157.865 67.5363L140.567 85.7443Z"
          fill="#5B1B76"
        />
        <path
          id="Vector_8"
          d="M144.209 158.577L122.286 49.1122C122.286 49.1122 72.2872 59.3431 67.7352 66.6263C63.1832 73.9094 56.8104 221.394 56.8104 221.394C56.8104 221.394 47.7064 250.527 56.8104 250.527C65.9144 250.527 102.33 224.125 104.151 234.14C105.972 244.154 107.793 249.616 120.538 246.885C133.284 244.154 141.477 222.304 141.477 222.304C141.477 222.304 138.746 266.004 144.209 266.004C149.671 266.004 157.865 268.735 157.865 264.183V166.77L179.714 87.5655L158.096 67.7086L148.761 74.8199L144.209 158.577Z"
          fill="#2F2E41"
        />
        <path
          id="Vector_9"
          d="M141.477 41.1351C141.477 41.1351 130.553 10.1816 155.133 2.89842C179.714 -4.38477 190.639 3.8088 194.281 8.36079C198.096 13.1297 216.13 36.5831 205.205 42.0455C194.281 47.5079 189.273 40.6799 189.273 37.9487C189.273 35.2175 172.886 37.9487 165.603 40.6799C163.627 41.5016 161.453 41.7239 159.352 41.3192C157.251 40.9145 155.315 39.9006 153.786 38.4039C153.786 38.4039 151.947 29.7551 146.485 34.3071C141.022 38.8591 141.477 41.1351 141.477 41.1351Z"
          fill="#2F2E41"
        />
        <g id="right-hand">
          <path
            id="Vector_10"
            d="M176.528 250.072L180.169 264.638C180.169 264.638 193.825 302.875 181.99 301.054C170.155 299.233 164.693 266.459 164.693 266.459L162.872 254.624L176.528 250.072Z"
            fill="#FFB6B6"
          />
          <path
            id="Vector_11"
            d="M173.797 89.8415L180.169 88.0207C180.169 88.0207 181.99 100.766 181.99 103.497C181.99 106.229 176.528 159.942 176.528 159.942L181.08 251.892L160.141 260.996L153.768 157.211L173.797 89.8415Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_12"
            d="M224.421 273.044C223.938 272.1 223.099 271.386 222.09 271.06C221.082 270.733 219.984 270.82 219.039 271.302L159.399 301.778C158.455 302.262 157.742 303.1 157.415 304.109C157.089 305.118 157.176 306.216 157.657 307.161L280.517 547.588C281.001 548.532 281.84 549.245 282.849 549.572C283.858 549.898 284.955 549.811 285.9 549.33L345.54 518.853C346.484 518.369 347.197 517.531 347.524 516.522C347.85 515.513 347.763 514.416 347.282 513.471L224.421 273.044Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_13"
            d="M206.966 342.268C218.564 342.268 227.966 332.866 227.966 321.268C227.966 309.67 218.564 300.268 206.966 300.268C195.368 300.268 185.966 309.67 185.966 321.268C185.966 332.866 195.368 342.268 206.966 342.268Z"
            fill="#5B1B76"
          />
          <path
            id="Vector_14"
            d="M241.502 358.086C241.079 357.259 240.346 356.634 239.463 356.348C238.58 356.063 237.619 356.139 236.793 356.562C235.966 356.984 235.341 357.718 235.055 358.601C234.769 359.484 234.846 360.444 235.269 361.271L310.35 508.199C310.559 508.609 310.846 508.973 311.196 509.271C311.546 509.57 311.951 509.796 312.389 509.938C312.826 510.08 313.287 510.134 313.746 510.098C314.204 510.061 314.651 509.934 315.06 509.725C315.47 509.516 315.834 509.228 316.132 508.878C316.43 508.528 316.657 508.123 316.798 507.685C316.939 507.247 316.993 506.786 316.956 506.328C316.919 505.87 316.793 505.423 316.583 505.014L241.502 358.086Z"
            fill="white"
          />
          <path
            id="Vector_15"
            d="M222.802 367.642C222.593 367.232 222.305 366.868 221.955 366.57C221.605 366.272 221.2 366.046 220.763 365.904C220.326 365.763 219.865 365.709 219.406 365.745C218.948 365.782 218.502 365.908 218.093 366.118C217.683 366.327 217.319 366.614 217.021 366.964C216.723 367.314 216.497 367.719 216.355 368.157C216.214 368.594 216.16 369.055 216.196 369.513C216.233 369.971 216.359 370.418 216.569 370.827L291.65 517.755C292.072 518.581 292.806 519.206 293.689 519.492C294.572 519.778 295.533 519.701 296.359 519.279C297.186 518.856 297.811 518.123 298.096 517.24C298.382 516.357 298.306 515.396 297.883 514.569L222.802 367.642Z"
            fill="white"
          />
        </g>
        <path
          id="Vector_16"
          opacity="0.2"
          d="M85.864 376.161L111.864 495.161L114.078 484.911L85.864 376.161Z"
          fill="black"
        />
        <g id="left-hand">
          <path
            id="Vector_17"
            d="M116.414 268.551C116.579 267.504 116.322 266.434 115.699 265.575C115.076 264.717 114.138 264.141 113.091 263.974L46.9427 253.477C45.8951 253.312 44.8247 253.569 43.9665 254.192C43.1082 254.815 42.5322 255.753 42.3648 256.801L0.0487732 523.464C-0.11622 524.512 0.141116 525.582 0.764304 526.44C1.38749 527.298 2.32564 527.874 3.37293 528.042L69.5209 538.538C70.5685 538.704 71.6387 538.446 72.4969 537.823C73.3551 537.2 73.931 536.262 74.0984 535.215L116.414 268.551Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_18"
            d="M73.9044 318.244C85.5023 318.244 94.9044 308.842 94.9044 297.244C94.9044 285.646 85.5023 276.244 73.9044 276.244C62.3064 276.244 52.9044 285.646 52.9044 297.244C52.9044 308.842 62.3064 318.244 73.9044 318.244Z"
            fill="#5B1B76"
          />
          <path
            id="Vector_19"
            d="M80.1301 347.338C80.2028 346.884 80.1852 346.42 80.0783 345.973C79.9715 345.525 79.7775 345.103 79.5075 344.731C79.2374 344.359 78.8966 344.043 78.5046 343.803C78.1125 343.562 77.6769 343.401 77.2226 343.329C76.7683 343.257 76.3042 343.275 75.857 343.382C75.4097 343.49 74.988 343.684 74.6159 343.955C74.2439 344.225 73.9288 344.566 73.6887 344.959C73.4486 345.351 73.2882 345.787 73.2166 346.241L47.3568 509.202C47.2114 510.119 47.436 511.056 47.9814 511.807C48.5268 512.558 49.3483 513.062 50.265 513.208C51.1818 513.353 52.1189 513.128 52.87 512.583C53.6211 512.038 54.1249 511.216 54.2703 510.299L80.1301 347.338Z"
            fill="white"
          />
          <path
            id="Vector_20"
            d="M59.3897 344.047C59.4617 343.593 59.4436 343.129 59.3365 342.683C59.2293 342.236 59.0351 341.814 58.7651 341.442C58.495 341.07 58.1544 340.755 57.7625 340.515C57.3707 340.275 56.9354 340.114 56.4814 340.042C56.0275 339.97 55.5638 339.988 55.1169 340.095C54.6699 340.202 54.2484 340.396 53.8765 340.666C53.5046 340.936 53.1895 341.277 52.9492 341.669C52.709 342.061 52.5482 342.496 52.4762 342.95L26.6164 505.911C26.5438 506.365 26.5614 506.829 26.6682 507.277C26.775 507.724 26.969 508.146 27.2391 508.518C27.5091 508.891 27.8499 509.206 28.2419 509.447C28.634 509.687 29.0696 509.848 29.5239 509.92C29.9782 509.993 30.4423 509.974 30.8896 509.867C31.3368 509.76 31.7585 509.565 32.1306 509.295C32.5026 509.024 32.8177 508.683 33.0578 508.291C33.2979 507.898 33.4583 507.463 33.5299 507.008L59.3897 344.047Z"
            fill="white"
          />
          <path
            id="Vector_21"
            d="M87.5279 227.072L91.1694 241.638C91.1694 241.638 104.825 279.875 92.9902 278.054C81.155 276.233 75.6927 243.459 75.6927 243.459L73.8719 231.624L87.5279 227.072Z"
            fill="#FFB6B6"
          />
          <path
            id="Vector_22"
            d="M80.936 68.1033L68.19 67.0813L30.864 173.598L73.653 247.34L103.696 224.58L71.832 172.687L96.413 106.228L80.936 68.1033Z"
            fill="#2F2E41"
          />
        </g>
      </svg>
    </div>
  );
};

export default Create;

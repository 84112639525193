import React from "react";
import { useSpring, animated } from "react-spring";
import "./styles2.css";

const calc = (x, y) => [
  -(y - window.innerHeight / 2) / 20,
  (x - window.innerWidth / 2) / 20,
  1.1,
];
const trans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const TextCard = ({
  prjName,
  prjDesc,
  btnText,
  callbackFromParent,
  callbackFromParent2,
  callbackFromParent3,
  callbackFromParent4,
  callbackFromParent5,
  callbackFromParent6,
  callbackFromParent7,
  callbackFromParent8,
  callbackFromParent9,
  callbackFromParent10,
  callbackFromParent11,
  callbackFromParent12,
  accentClr,
  backAccentClr,
}) => {
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }));

  //   var img = process.env.PUBLIC_URL + "/assets/svgs/" + imgName;/

  return (
    <>
      <animated.div
        // style={{ backgroundImage: `url()` }}
        className="card2"
        onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
        onMouseLeave={() => set({ xys: [0, 0, 1] })}
        style={{
          transform: props.xys.interpolate(trans),
          background: backAccentClr,
        }}
      >
        <div
          className="details"
          style={{
            border: `1px solid ${backAccentClr}`,
            backgroundColor: backAccentClr,
          }}
        >
          <h3
            className="first-proj-title"
            style={{ color: accentClr, opacity: 1 }}
          >
            {prjName}
          </h3>
          <p className="first-proj-p" style={{ color: accentClr }}>
            {prjDesc}
          </p>
          {/* <br /> */}
          <button
            style={{ border: `1px solid ${accentClr}`, color: accentClr }}
            className="btn-port-2"
            onClick={() => {
              if (prjName === "DairyPal") {
                callbackFromParent("none");
                callbackFromParent2("block");
                callbackFromParent3("block");
                callbackFromParent4("none");
                callbackFromParent5("block");
                callbackFromParent6("none");
                callbackFromParent7("block");
                callbackFromParent8("none");
                callbackFromParent9("block");
                callbackFromParent10("none");
                callbackFromParent11("block");
                callbackFromParent12("none");
              } else if (prjName === "Coignitive Agent") {
                callbackFromParent("block");
                callbackFromParent2("none");
                callbackFromParent3("none");
                callbackFromParent4("block");
                callbackFromParent5("block");
                callbackFromParent6("none");
                callbackFromParent7("block");
                callbackFromParent8("none");
                callbackFromParent9("block");
                callbackFromParent10("none");
                callbackFromParent11("block");
                callbackFromParent12("none");
              } else if (prjName === "FrostieVR") {
                callbackFromParent("block");
                callbackFromParent2("none");
                callbackFromParent3("block");
                callbackFromParent4("none");
                callbackFromParent5("none");
                callbackFromParent6("block");
                callbackFromParent7("block");
                callbackFromParent8("none");
                callbackFromParent9("block");
                callbackFromParent10("none");
                callbackFromParent11("block");
                callbackFromParent12("none");
              } else if (prjName === "Jozeko") {
                callbackFromParent("block");
                callbackFromParent2("none");
                callbackFromParent3("block");
                callbackFromParent4("none");
                callbackFromParent5("block");
                callbackFromParent6("none");
                callbackFromParent7("none");
                callbackFromParent8("block");
                callbackFromParent9("block");
                callbackFromParent10("none");
                callbackFromParent11("block");
                callbackFromParent12("none");
              } else if (prjName === "Broadband") {
                callbackFromParent("block");
                callbackFromParent2("none");
                callbackFromParent3("block");
                callbackFromParent4("none");
                callbackFromParent5("block");
                callbackFromParent6("none");
                callbackFromParent7("block");
                callbackFromParent8("none");
                callbackFromParent9("none");
                callbackFromParent10("block");
                callbackFromParent11("block");
                callbackFromParent12("none");
              } else if (prjName === "Aceso") {
                callbackFromParent("block");
                callbackFromParent2("none");
                callbackFromParent3("block");
                callbackFromParent4("none");
                callbackFromParent5("block");
                callbackFromParent6("none");
                callbackFromParent7("block");
                callbackFromParent8("none");
                callbackFromParent9("block");
                callbackFromParent10("none");
                callbackFromParent11("none");
                callbackFromParent12("block");
              }
            }}
          >
            {btnText}
          </button>
        </div>
      </animated.div>
      {/* <p>{imgTxt}</p> */}
    </>
  );
};

export default TextCard;

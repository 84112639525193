import React from "react";
import { Zoom } from "react-reveal";

const PortfolioIntro = () => {
  return (
    <>
      <div className="intro-main">
        <div className="intro-text">
          <h1 className="intro-h">
            <Zoom left cascade>
              I N T R O D U C T I O N
            </Zoom>
          </h1>
          <div className="hr-intro-tag"></div>
          <Zoom right cascade>
            <div>
              <h2 className="intro-p">
                Thank you for taking the time to visit. We are SenaryBits. We
                build beautiful hand-crafted websites and much more. We provide
                you paramount solutions with exceptional skills, strenuous
                process and lissom flexibility. We are the best app development
                company, trying to serve and transfigure the field of
                information and technology with a subtle incorporation of
                Artificial Intelligence and Machine Learning. We help businesses
                to meet operational challenges of today’s financial world and
                empower them to achieve business excellence. We’d love to work
                for you.
              </h2>
            </div>
          </Zoom>
        </div>
      </div>
    </>
  );
};

export default PortfolioIntro;

import React from "react";
import {
  MailFilled,
  LinkedinFilled,
  InstagramFilled,
  TwitterOutlined,
  PhoneFilled,
  FacebookFilled,
} from "@ant-design/icons";
// import { Link } from "react-router-dom";

import "./Footer.css";

const FooterBottom = (props) => {
  const imgPath = process.env.PUBLIC_URL + "/assets/images/";

  return (
    <>
      <div
        style={{
          width: "80%",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "100px",
          justifyContent: "center",
        }}
        id="contact-us"
      >
        <h5
          style={{
            fontWeight: "bold",
            display: props.display,
            color: "#5b1b76",
          }}
        >
          GET IN TOUCH
        </h5>
        <p
          style={{
            color: "#686868",
            fontSize: "14px",
            fontWeight: 500,
            marginTop: "5px",
          }}
        >
          We’d love to connect and hear more about how we can help. Just Buzz
          us! We are here for you.
        </p>
        <div
          style={{
            marginTop: "50px",
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ textAlign: "left" }} className="socials-mail">
            <p
              className="mail-top"
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                lineHeight: "30px",
                height: "20px",
                textDecoration: "none",
              }}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="mail-link"
                href="mailto:info@senarybits.com"
                style={{ textDecoration: "none" }}
              >
                <MailFilled className="mail-logo" />{" "}
                <span style={{ paddingLeft: "15px", fontSize: "14px" }}>
                  info@senarybits.com
                </span>
              </a>
            </p>
            {/* <br /> */}
            <p
              className="loc-top"
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                lineHeight: "30px",
                height: "20px",
              }}
            >
              <a
                rel="noopener noreferrer"
                target="_blank"
                className="loc-link"
                href="https://goo.gl/maps/FG7oYxxqiLzWC2Pz6"
                style={{ textDecoration: "none" }}
              >
                <img
                  alt=""
                  src={`${imgPath}maps-logo.png`}
                  className="loc-logo-clr"
                />{" "}
                <img
                  alt=""
                  src={`${imgPath}maps-purple-logo.png`}
                  className="loc-logo-prp"
                />{" "}
                <span className="font-class" style={{ paddingLeft: "15px" }}>
                  387 Sadiq Centre, JB Johar Town, Lahore
                </span>
              </a>
            </p>
            <p
              className="num-top"
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                lineHeight: "30px",
                height: "20px",
              }}
            >
              <a
                rel="noopener noreferrer"
                target="_blank"
                className="num-link"
                href="tel:+923312500085"
                style={{ textDecoration: "none" }}
              >
                <PhoneFilled className="num-logo" />
                <span className="num-text" style={{ paddingLeft: "15px" }}>
                  +92 331 2500 085
                </span>
              </a>
            </p>
          </div>
          <div className="socials-media">
            <p
              className="social-tag"
              style={{
                fontWeight: 900,
                fontSize: "10px",
                lineHeight: "30px",
                height: "20px",
                color: "#a6a6a6",
              }}
            >
              FOLLOW US ON SOCIAL MEDIA
            </p>
            <div>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://facebook.com/senarybits"
              >
                <FacebookFilled className="fb" />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://instagram.com/senarybits"
              >
                <InstagramFilled className="insta" />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://linkedin.com/company/senarybits/"
              >
                <LinkedinFilled className="linkedin" />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://twitter.com/senarybits"
              >
                <TwitterOutlined className="twitter" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div style={{ textAlign: "center" }}>
        {" "}
        <hr
          style={{
            backgroundColor: "#a6a6a6",
          }}
        />
        <p
          style={{
            marginTop: "30px",
            color: "#686868",
            marginBottom: "30px",
            // fontSize: "16px",
            fontWeight: 500,
          }}
          className="footer-text"
        >
          &copy; {new Date().getFullYear()}
          <a
            href="/"
            style={{
              color: "#9533be",
              fontWeight: "bold",
              textDecoration: "none",
            }}
          >
            {" "}
            SenaryBits
          </a>{" "}
          All coprights are reserved.
        </p>
      </div>
    </>
  );
};

export default FooterBottom;

import React from "react";
import "./Footer.css";
const FooterCards = (props) => {
  const imagePath = process.env.PUBLIC_URL + "/assets/svgs/";

  // function hoverInToggle(e) {
  //   e.target.style.color = "#5b1b76";
  //   e.target.style.fontSize = "12.3px";
  // }

  // function hoverOutToggle(e) {
  //   e.target.style.color = "#fff";
  // }

  return (
    <div className={props.mainDivName}>
      <img
        className={props.mainImage}
        alt=""
        //   style={{ visibility: visibility }}
        src={`${imagePath + props.imageName}.svg`}
        // height={props.height}
        // width={props.width}
        // height="100px"
        // width="80px"
      />
      <h5
        className={props.headName}
        style={{
          marginTop: "30px",
          fontWeight: "bolder",
          color: "#fff",
        }}
      >
        {props.heading}
      </h5>
      <p
        style={{
          color: "#F0F0F0",
          fontWeight: 500,
        }}
      >
        {props.description}
      </p>
      {/* <a
        onMouseOver={hoverInToggle}
        onMouseLeave={hoverOutToggle}
        href="/"
        className={props.learnName}
        style={{
          color: "#dcdcdc",
          fontWeight: "bolder",
          textDecoration: "none",
        }}
      >
        LEARN MORE &nbsp;
        <span className="arrow" style={{ fontSize: "14px" }}>
          →
        </span>
      </a> */}
    </div>
  );
};

export default FooterCards;

import React from "react";
// import PortfolioImg from "../SVGs/PortfolioImg";
import "./Portfolio.css";
import { isMobile } from "react-device-detect";
import { Zoom } from "react-reveal";
// import Monsters2 from "../SVGs/Monsters2";
import ParticlesBg from "particles-bg";

const Title = () => {
  const imgPath = process.env.PUBLIC_URL + "/assets/images/";

  return (
    <>
      <div className="main-title">
        {isMobile ? (
          <ParticlesBg type="cobweb" color="#e7dff3" num={100} bg={true} />
        ) : (
          <ParticlesBg type="cobweb" color="#e7dff3" num={200} bg={true} />
        )}
        <div className="design">
          <div>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => window.location.replace("/")}
              className="logo-img"
              src={`${imgPath}/logo-with-title.png`}
              alt=""
            />
          </div>
          <div className="hr-tag"></div>

          <h1 className="text">
            <Zoom left cascade>
              P O R T F O L I O
            </Zoom>
          </h1>
        </div>
        <br />
        {/* <div className="pi-svg">
          <Fade right>
            <Monsters2 />
          </Fade>
        </div> */}
      </div>
    </>
  );
};

export default Title;

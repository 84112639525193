import React from "react";
// import Data from "../SVGs/Data";
// import Des from "../SVGs/Des";
// import ScrollAnimation from "react-animate-on-scroll";
import TechDev from "../SVGs/TechDev";
// import Writing from "../SVGs/Writing";
import "./TechList.css";

const TechList = () => {
  const svgPath = process.env.PUBLIC_URL + "/assets/svgs/";

  return (
    <div>
      {/* <span>Technology & Development</span>
                <span>Designing</span>
                <span>Machine Learning</span>
                <span>Artificial Intelligene</span>
                <span>Writing</span> */}
      <div className="list-header"></div>
      <div
        style={{
          // border: "1px solid #b7acd8",
          borderRadius: "32px",
          padding: "20px",
          // backgroundColor: "#b7acd8",
        }}
      >
        <div className="main-div">
          <div className="heading animate__animated animate__fadeIn animate__slow">
            <TechDev />
            <h5 style={{ fontWeight: "bold", color: "#5b1b76" }}>
              Tech & Development
            </h5>
          </div>
          <span style={{ width: "40px", height: "20px" }}></span>
          <hr className="resp-hr" />
          <hr className="hr-hor" />
          <div className="table-of-tech animate__animated  animate__zoomIn">
            {/* <ScrollAnimation
              delay={100}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                style={{ color: "blue" }}
                src={`${svgPath}react-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>React</p>
            </div>
            {/* </ScrollAnimation> */}
            {/* <ScrollAnimation
              delay={200}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img alt="" className="image-hw" src={`${svgPath}vue-icon.svg`} />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Vue.js</p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={300}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}angular-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Angular.js</p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={400}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}html-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>HTML5</p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={500}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}bootstrap-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Bootstrap</p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={600}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img alt="" className="image-hw" src={`${svgPath}css-icon.svg`} />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>CSS3</p>
            </div>
            {/* </ScrollAnimation>

            <ScrollAnimation
              delay={700}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}javascript-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Javascript</p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={800}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}django-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Django</p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={900}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}node-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Node.js</p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={1000}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}java-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>JAVA</p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={1100}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}python-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Python</p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={1200}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}mongo-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>MongoDB</p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={1300}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img alt="" className="image-hw" src={`${svgPath}sql-icon.svg`} />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>MySQL</p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={1400}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}postgre-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>PostgreSQL</p>
            </div>
            {/* </ScrollAnimation> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechList;

import React from "react";
import { useSpring, animated } from "react-spring";
import "./styles.css";

const calc = (x, y) => [
  -(y - window.innerHeight / 2) / 20,
  (x - window.innerWidth / 2) / 20,
  1.1,
];
const trans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const Card = ({ imgName }) => {
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }));

  var img = process.env.PUBLIC_URL + "/assets/svgs/" + imgName;
  // console.log("DA", imgName);

  return (
    <>
      <animated.div
        // style={{ backgroundImage: `url()` }}
        className="card"
        onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
        onMouseLeave={() => set({ xys: [0, 0, 1] })}
        style={{ transform: props.xys.interpolate(trans) }}
      >
        <img className="img-src" src={img} alt="" />
      </animated.div>
      {/* <p>{imgTxt}</p> */}
    </>
  );
};

export default Card;

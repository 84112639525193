import React from "react";
import "./Intro.css";
import { ApiTwoTone, BulbTwoTone } from "@ant-design/icons";
import Cartoon from "../SVGs/Cartoon";

const Intro = () => {
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setTimeout(function () {
  //     setLoading(false);
  //   }, 2000);
  // });

  return (
    <div
      style={{
        // backgroundColor: "blue",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      className="intro-class"
      id="intro"
    >
      <div className="upper-div">
        <h3
          className="title"
          style={{
            color: "#9533be",
            fontWeight: "bold",
          }}
        >
          Make your dreams come true with us
        </h3>

        <p
          className="descForIntro"
          style={{
            color: "#686868",
            margintop: "5px",
            fontWeight: 500,
            fontSize: "15px",
          }}
        >
          Technologies have given the new turn to society, advancement in
          technologies have made our lives better and easy. <br />
          <br />
          SenaryBits create digital products that bring your ideas to life,
          delight your users and help you achieve your goals. We put stock in
          supporting long haul associations with our customers. You need a
          partner you can trust to regenerate your thought as well as to develop
          with. We have quickly manufactured a top-notch group of engineers
          prepared to take on assorted and provoking undertakings with a mean to
          make a genuine incentive for our clients. Give us an issue and we will
          design an answer! <br />
          <br />
          Our group of A-list engineers has created venture and expository
          programming utilized by a huge number of clients consistently.
        </p>
        <br />
        <div className="btns-intro">
          <a href="#what-we-deal-in" className="tech-button">
            <ApiTwoTone
              className="purple-two-tone"
              style={{ fontSize: "24px", margin: "auto" }}
              twoToneColor="#9533be"
            />{" "}
            <ApiTwoTone
              className="white-two-tone"
              style={{ fontSize: "24px", margin: "auto" }}
              twoToneColor="#fff"
            />{" "}
            Our technologies?
          </a>

          <a href="#contact-us" className="queries-button">
            <BulbTwoTone
              className="bulb-white"
              style={{ fontSize: "24px", margin: "auto" }}
              twoToneColor="#fff"
            />{" "}
            Have any queries?
          </a>
        </div>
      </div>
      <div className="svg-dream">
        <Cartoon />
        {/* lol */}
      </div>
    </div>
    // )}
    // </>
  );
};

export default Intro;

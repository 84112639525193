import React from "react";
import "./Design.css";

const Design = () => {
  return (
    <div>
      <svg
        className="design-class"
        viewBox="0 0 730 651"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="undraw fitting pieces cli3 1">
          <path
            id="filled-circle"
            d="M123 515.832C190.931 515.832 246 460.763 246 392.832C246 324.901 190.931 269.832 123 269.832C55.069 269.832 0 324.901 0 392.832C0 460.763 55.069 515.832 123 515.832Z"
            fill="#5B1B76"
          />
          <g id="upper-square">
            <path
              id="Vector"
              d="M231 89.8318H123V197.832H231V89.8318Z"
              fill="#F2F2F2"
            />
            <path
              id="Vector 2"
              d="M246 73.8318H138V181.832H246V73.8318Z"
              fill="#5B1B76"
            />
            <path
              id="Vector 3"
              d="M299 159.832H157V17.8318H299V159.832ZM159 157.832H297V19.8318H159V157.832Z"
              fill="#3F3D56"
            />
          </g>
          <g id="right-square">
            <path
              id="Vector 4"
              d="M332 259.832H304V287.832H332V259.832Z"
              fill="#F2F2F2"
            />
            <path
              id="Vector 5"
              d="M338 252.832H310V280.832H338V252.832Z"
              fill="#5B1B76"
            />
            <path
              id="Vector 6"
              d="M365 270.832H319V224.832H365V270.832ZM321 268.832H363V226.832H321V268.832Z"
              fill="#3F3D56"
            />
          </g>
          <path
            id="empty-circle"
            d="M231 649.832C146.636 649.832 78 581.196 78 496.832C78 412.468 146.636 343.832 231 343.832C315.364 343.832 384 412.468 384 496.832C384 581.196 315.364 649.832 231 649.832ZM231 345.832C147.738 345.832 80 413.57 80 496.832C80 580.094 147.738 647.832 231 647.832C314.262 647.832 382 580.094 382 496.832C382 413.57 314.262 345.832 231 345.832Z"
            fill="#3F3D56"
          />
          <path
            id="Vector 7"
            d="M577.27 645.508H418.857V647.382H577.27V645.508Z"
            fill="#3F3D56"
          />
          <path
            id="Vector 8"
            d="M729.076 500.829L575.468 463.832L575 465.776L728.607 502.773L729.076 500.829Z"
            fill="#3F3D56"
          />
          <path
            id="Vector 9"
            d="M455.5 555.332L468.5 601.332L466.5 618.332L504.5 610.332C504.5 610.332 488.5 583.332 490.5 569.332L455.5 555.332Z"
            fill="#FFB8B8"
          />
          <path
            id="Vector 10"
            d="M627.5 390.332L617.5 444.332L650.5 439.332L652 382.832L627.5 390.332Z"
            fill="#FFB8B8"
          />
          <path
            id="Vector 11"
            d="M517 120.832L515 140.832L499 149.832L541 242.832L573 165.832L552 149.832L550 111.832L517 120.832Z"
            fill="#FFB8B8"
          />
          <path
            id="Vector 12"
            d="M546.5 220.332L505.545 146.151C505.545 146.151 472.5 148.332 464.5 155.332L480.5 257.332L475.5 270.332C475.5 270.332 434.5 312.332 442.5 403.332C450.5 494.332 450.5 565.332 450.5 565.332C450.5 565.332 479.5 599.332 539.5 565.332V384.332L624.5 377.332V399.332C624.5 399.332 712.5 377.332 714.5 344.332L691.862 305.013C687.488 297.417 680.679 291.518 672.537 288.272C664.395 285.026 655.396 284.623 646.995 287.127L572.5 309.332L564.5 279.332L572.5 257.332C572.5 257.332 593.5 241.332 593.5 220.332C593.5 199.332 599.5 174.332 599.5 174.332L560.601 156.385L546.5 220.332Z"
            fill="#2F2E41"
          />
          <path
            id="Vector 13"
            d="M617.5 212.332L633.5 251.332C633.5 251.332 650.5 271.332 653.5 293.332L658.5 319.332C658.5 319.332 670.5 365.332 651.5 363.332C632.5 361.332 637.5 317.332 637.5 317.332L634.5 304.332L617.5 276.332L592.415 228.652L617.5 212.332Z"
            fill="#FFB8B8"
          />
          <path
            id="shoes"
            d="M622.225 430.184C622.225 430.184 650.359 436.112 657.433 423.911L683.419 450.015C683.419 450.015 733.545 469.546 703.429 478.38C675.358 486.614 649.839 471.701 649.536 474.446C649.536 474.446 642.352 466.611 632.858 470.594C623.364 474.577 597.075 466.646 598.946 458.804C600.817 450.962 607.973 422.575 622.225 430.184Z"
            fill="#2F2E41"
          />
          <path
            id="Vector 14"
            d="M467.5 606.332C467.5 606.332 496.115 609.139 501.807 596.236L530.5 619.332C530.5 619.332 582.666 633.741 553.5 645.332C526.528 656.051 499.514 644.735 499.5 647.332C499.5 647.332 491.5 640.332 482.5 645.332C473.5 650.332 446.5 645.332 447.5 637.332C448.5 629.332 452.5 600.332 467.5 606.332Z"
            fill="#2F2E41"
          />
          <path
            id="Vector 15"
            d="M586.5 170.332L599.5 174.332L627.5 216.332L576.5 250.332L586.5 170.332Z"
            fill="#2F2E41"
          />
          <g id="face">
            <path
              id="Vector 16"
              d="M517.314 34.2946C487.132 42.4706 470.021 76.2508 479.095 109.746L490.623 152.301L503.711 148.756L507.017 130.936L509.394 147.216L593.519 124.427L596.524 108.228L598.685 123.028L608.156 120.462L599.105 87.0488C588.664 48.5049 552.045 24.8863 517.314 34.2946Z"
              fill="#2F2E41"
            />
            <path
              id="Vector 17"
              d="M528.5 130.332C548.382 130.332 564.5 114.214 564.5 94.3318C564.5 74.4495 548.382 58.3318 528.5 58.3318C508.618 58.3318 492.5 74.4495 492.5 94.3318C492.5 114.214 508.618 130.332 528.5 130.332Z"
              fill="#FFB8B8"
            />
            <path
              id="Vector 18"
              d="M537.375 46.7925C529.974 41.2138 520.669 38.9312 511.635 41.3784L510.562 41.6691C489.876 47.2729 478.177 70.5344 484.432 93.6248L491.364 91.7478L490.175 82.9238L494.124 90.9998L535.234 79.8628L536.726 71.7216L537.818 79.1638L545.905 76.9728C554.756 94.6928 554.411 114.904 544.869 137.606L558.646 133.874L561.629 117.591L563.812 132.475L590.074 125.36L586.258 90.8008C579.531 65.9679 559.397 48.8185 537.375 46.7925Z"
              fill="#2F2E41"
            />
            <path
              id="Vector 19"
              d="M546 42.8318C555.941 42.8318 564 34.7729 564 24.8318C564 14.8907 555.941 6.8318 546 6.8318C536.059 6.8318 528 14.8907 528 24.8318C528 34.7729 536.059 42.8318 546 42.8318Z"
              fill="#2F2E41"
            />
            <path
              id="Vector 20"
              d="M545 34.8318C540.785 34.8342 536.704 33.3567 533.467 30.657C530.23 27.9573 528.044 24.2069 527.29 20.0602C526.536 15.9134 527.262 11.6336 529.341 7.9674C531.42 4.3012 534.721 1.4814 538.667 0C536.147 0.3233 533.725 1.1765 531.559 2.5035C529.393 3.8305 527.532 5.6011 526.1 7.6989C524.668 9.7967 523.696 12.1741 523.248 14.6745C522.801 17.175 522.888 19.7418 523.504 22.2061C524.121 24.6704 525.252 26.9763 526.823 28.9719C528.395 30.9676 530.371 32.6079 532.622 33.7847C534.873 34.9616 537.348 35.6484 539.884 35.7998C542.419 35.9513 544.958 35.5641 547.333 34.6637C546.56 34.7701 545.781 34.8262 545 34.8318Z"
              fill="#2F2E41"
            />
            <path
              id="Vector 21"
              d="M551.5 100.832C553.985 100.832 556 97.9218 556 94.3318C556 90.7418 553.985 87.8318 551.5 87.8318C549.015 87.8318 547 90.7418 547 94.3318C547 97.9218 549.015 100.832 551.5 100.832Z"
              fill="#FFB8B8"
            />
          </g>
          <path
            id="Vector 22"
            d="M471.5 156.332H464.5C464.5 156.332 453.5 163.332 444.5 193.332C435.5 223.332 434.5 224.332 434.5 224.332L481.5 235.332L471.5 156.332Z"
            fill="#2F2E41"
          />
          <g id="hand">
            <path
              id="Vector 23"
              d="M465 444.832C440.245 441.147 410.258 442.004 378 444.832C391.449 420.172 391.449 398.506 378 379.832C406.894 383.34 436.106 383.34 465 379.832C452.611 400.458 453.704 422.216 465 444.832Z"
              fill="#5B1B76"
            />
            <path
              id="Vector 24"
              d="M453.5 215.332L450.5 255.332C450.5 255.332 436.5 277.332 433.5 324.332L432.5 353.332C432.5 353.332 411.5 394.332 428.5 393.332C445.5 392.332 444.5 354.332 444.5 354.332L474.5 267.332V225.332L453.5 215.332Z"
              fill="#FFB8B8"
            />
            <path
              id="Vector 25"
              d="M436 399.832H408V427.832H436V399.832Z"
              fill="#D0CDE1"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Design;

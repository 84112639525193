import React from "react";
import Programmers from "../SVGs/Programmers";
// import ScrollAnimation from "react-animate-on-scroll";
// import Programmers from "../SVGs/Programmers";
import "./WhoWeAre.css";

const WhoWeAre = () => {
  // const imgPath = process.env.PUBLIC_URL + "/assets/images/";

  return (
    <div
      id="who-we-are"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
      }}
      className="wwr-class"
    >
      <div className="upper-div-wwr">
        <h3
          className="title-wwr"
          style={{ color: "#9533be", fontWeight: "bold" }}
        >
          Who We Are
        </h3>

        <p
          className="descForIntro-wwr"
          style={{
            color: "#686868",
            fontSize: "15px",
            margintop: "5px",
            fontWeight: 500,
          }}
        >
          We are a digital studio based out of Lahore (Pakistan) working to help
          global clients create, connect, improve and grow their digital
          products initiatives & ideas. <br />
          <br />
          Our team consists of software engineers, developers, UX architects,
          and designers capable of producing impactful software. We create
          software that is used by millions of users, in sectors spanning
          Education, Technology, Healthcare, Travel and beyond. We thrive on
          creativity and collaboration. To ensure collaboration, we encourage
          our teams to enjoy their social lives and facilitate it.
          <br />
          <br />
          We’re not afraid to make mistakes, instead, we learn from them and
          adjust our strategy, and reward high achievers. SenaryBits goal is to
          convert your imaginative product into reality.
        </p>
        <br />
      </div>
      <div className="img-upper">
        {/* <img className="prog-img" src={`${imgPath}programmers.png`} alt="" /> */}
        <Programmers />
      </div>
    </div>
  );
};

export default WhoWeAre;

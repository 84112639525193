import React from "react";
import "./Data.css";

const Data = () => {
  return (
    <div>
      <svg
        className="data-class"
        // width="806"
        // height="602"
        viewBox="0 0 806 602"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="undraw Data points re vkpq 1">
          <path
            id="Vector"
            d="M661.354 0H174.354C172.498 0.00205925 170.719 0.740212 169.407 2.05252C168.095 3.36483 167.356 5.14412 167.354 7V337C167.356 338.856 168.095 340.635 169.407 341.947C170.719 343.26 172.498 343.998 174.354 344H661.354C663.21 343.998 664.989 343.26 666.302 341.947C667.614 340.635 668.352 338.856 668.354 337V7C668.352 5.14412 667.614 3.36483 666.302 2.05252C664.989 0.740212 663.21 0.00205925 661.354 0ZM666.354 337C666.354 338.326 665.827 339.597 664.889 340.535C663.952 341.473 662.68 341.999 661.354 342H174.354C173.028 341.999 171.757 341.473 170.819 340.535C169.882 339.597 169.355 338.326 169.354 337V7C169.355 5.67408 169.882 4.40262 170.819 3.46506C171.757 2.52749 173.028 2.00053 174.354 2H661.354C662.68 2.00053 663.952 2.52749 664.889 3.46506C665.827 4.40262 666.354 5.67408 666.354 7V337Z"
            fill="#3F3D56"
          />
          <path
            id="Vector 2"
            d="M667.354 28.04H168.354V30.04H667.354V28.04Z"
            fill="#3F3D56"
          />
          <path
            id="Vector 3"
            d="M185.354 21C188.668 21 191.354 18.3137 191.354 15C191.354 11.6863 188.668 9 185.354 9C182.041 9 179.354 11.6863 179.354 15C179.354 18.3137 182.041 21 185.354 21Z"
            fill="#3F3D56"
          />
          <path
            id="Vector 4"
            d="M202.604 21C205.918 21 208.604 18.3137 208.604 15C208.604 11.6863 205.918 9 202.604 9C199.291 9 196.604 11.6863 196.604 15C196.604 18.3137 199.291 21 202.604 21Z"
            fill="#3F3D56"
          />
          <path
            id="Vector 5"
            d="M219.854 21C223.168 21 225.854 18.3137 225.854 15C225.854 11.6863 223.168 9 219.854 9C216.541 9 213.854 11.6863 213.854 15C213.854 18.3137 216.541 21 219.854 21Z"
            fill="#3F3D56"
          />
          <path
            id="Vector 6"
            d="M459.854 74.5H375.854C373.998 74.5 372.217 73.7625 370.905 72.4498C369.592 71.137 368.854 69.3565 368.854 67.5C368.854 65.6435 369.592 63.863 370.905 62.5502C372.217 61.2375 373.998 60.5 375.854 60.5H459.854C461.711 60.5 463.491 61.2375 464.804 62.5502C466.117 63.863 466.854 65.6435 466.854 67.5C466.854 69.3565 466.117 71.137 464.804 72.4498C463.491 73.7625 461.711 74.5 459.854 74.5Z"
            fill="#CCCCCC"
          />
          <path
            id="Vector 7"
            d="M517.354 108.5H318.354C316.498 108.5 314.717 107.763 313.405 106.45C312.092 105.137 311.354 103.357 311.354 101.5C311.354 99.6435 312.092 97.863 313.405 96.5502C314.717 95.2375 316.498 94.5 318.354 94.5H517.354C519.211 94.5 520.991 95.2375 522.304 96.5502C523.617 97.863 524.354 99.6435 524.354 101.5C524.354 103.357 523.617 105.137 522.304 106.45C520.991 107.763 519.211 108.5 517.354 108.5Z"
            fill="#CCCCCC"
          />
          <path
            id="Vector 8"
            d="M165.63 590.417L177.255 589.098L177.699 543.633L160.542 545.58L165.63 590.417Z"
            fill="#A0616A"
          />
          <path
            id="Vector 9"
            d="M200.898 597.348L164.364 601.494L162.762 587.378L185.181 584.834C188.925 584.409 192.684 585.489 195.631 587.836C198.579 590.183 200.473 593.604 200.898 597.348Z"
            fill="#2F2E41"
          />
          <path
            id="Vector 10"
            d="M46.081 589.691L57.78 589.69L63.345 544.565L46.079 544.566L46.081 589.691Z"
            fill="#A0616A"
          />
          <path
            id="Vector 11"
            d="M80.343 600.553L43.5741 600.554L43.5734 586.348L66.136 586.347C69.904 586.347 73.517 587.844 76.181 590.508C78.845 593.172 80.342 596.785 80.343 600.553Z"
            fill="#2F2E41"
          />
          <path
            id="Vector 12"
            d="M130.019 387.895L136.699 409.623L172.961 488.092L182.503 562.525L160.555 563.479L147.195 494.772L101.391 445.151L61.312 574.93L43.1809 573.022C43.1809 573.022 58.449 405.072 79.443 387.895C79.443 387.895 113.796 371.672 130.019 387.895Z"
            fill="#2F2E41"
          />
          <path
            id="Vector 13"
            d="M100.388 294.142C113.332 294.142 123.825 283.649 123.825 270.705C123.825 257.76 113.332 247.267 100.388 247.267C87.444 247.267 76.95 257.76 76.95 270.705C76.95 283.649 87.444 294.142 100.388 294.142Z"
            fill="#A0616A"
          />
          <path
            id="Vector 14"
            d="M132.404 392.189C132.404 392.189 135.267 294.854 95.1881 301.534C55.1092 308.214 71.8091 402.209 71.8091 402.209C71.8091 402.209 80.3971 419.386 101.391 400.3C122.385 381.215 132.404 392.189 132.404 392.189Z"
            fill="#5B1B76"
          />
          <g id="girl-hand">
            <path
              id="Vector 15"
              d="M215.378 311.172C214.626 312.356 213.623 313.361 212.44 314.115C211.257 314.87 209.924 315.356 208.533 315.539C207.142 315.722 205.728 315.598 204.39 315.176C203.052 314.754 201.824 314.043 200.79 313.095L170.769 329.268L172.341 311.618L200.608 298.965C202.317 297.334 204.565 296.387 206.925 296.303C209.285 296.219 211.595 297.004 213.415 298.508C215.236 300.013 216.441 302.133 216.803 304.467C217.165 306.801 216.658 309.187 215.378 311.172Z"
              fill="#A0616A"
            />
            <path
              id="Vector 16"
              d="M100.164 313.418L148.895 321.655L192.565 301.04L199.195 315.217L151.389 345.667C151.389 345.667 100.777 349.104 89.075 335.756C84.687 330.751 84.185 326.196 85.273 322.456C86.225 319.392 88.264 316.782 91.007 315.118C93.75 313.453 97.006 312.848 100.164 313.418Z"
              fill="#5B1B76"
            />
          </g>
          <path
            id="Vector 17"
            d="M97.6 295.331C97.712 295.231 97.799 295.106 97.854 294.966C97.91 294.826 97.931 294.676 97.918 294.526C97.905 294.376 97.857 294.232 97.778 294.104C97.699 293.976 97.591 293.868 97.464 293.789C95.382 292.5 93.687 290.674 92.555 288.503C91.423 286.333 90.896 283.897 91.03 281.453C91.165 279.009 91.955 276.645 93.318 274.612C94.681 272.578 96.567 270.95 98.777 269.896C105.668 266.611 115.005 269.167 120.099 262.854C121.135 261.503 121.867 259.945 122.247 258.285C122.626 256.626 122.643 254.904 122.296 253.237C121.197 247.274 116.714 242.846 111.694 239.811C105.527 236.083 98.439 234.157 91.234 234.251C84.028 234.344 76.993 236.454 70.925 240.342C64.857 244.229 59.999 249.738 56.9013 256.244C53.8038 262.751 52.5906 269.995 53.3991 277.156C54.0471 282.895 55.818 289.33 52.4055 293.99C49.4021 298.09 43.733 298.859 38.7531 299.878C28.0719 302.064 17.7672 307.106 10.5188 315.25C3.27033 323.394 -0.596467 334.82 1.58893 345.501C3.77433 356.183 12.542 365.57 23.3181 367.225C32.0893 368.573 41.1462 364.848 47.5874 358.744C54.0285 352.639 58.1 344.396 60.668 335.901C63.716 325.819 64.918 314.955 70.421 305.974C75.806 297.185 87.411 290.885 96.506 295.462C96.68 295.554 96.878 295.59 97.073 295.566C97.269 295.543 97.452 295.461 97.6 295.331Z"
            fill="#2F2E41"
          />
          <path
            id="Vector 18"
            d="M776.808 468.816C778.077 468.185 779.191 467.283 780.074 466.175C780.956 465.066 781.584 463.778 781.915 462.4C782.245 461.022 782.269 459.589 781.986 458.201C781.702 456.813 781.118 455.504 780.274 454.366L802.856 329.391L781.783 329.113L766.101 452.697C764.284 454.242 763.096 456.4 762.763 458.762C762.43 461.124 762.975 463.526 764.295 465.513C765.615 467.499 767.618 468.933 769.924 469.542C772.23 470.151 774.68 469.892 776.808 468.816Z"
            fill="#FFB8B8"
          />
          <path
            id="Vector 19"
            d="M711.406 462.891L703.406 500.891L704.406 585.891H719.406L726.406 502.891L737.406 467.891L711.406 462.891Z"
            fill="#FFB8B8"
          />
          <path
            id="Vector 20"
            d="M760.98 465.742L752.98 503.742L753.98 588.742H768.98L775.98 505.742L786.98 470.742L760.98 465.742Z"
            fill="#FFB8B8"
          />
          <path
            id="Vector 21"
            d="M699.167 584.925L722.811 584.865L722.848 599.752L684.318 599.849C684.313 597.894 684.693 595.957 685.437 594.149C686.18 592.341 687.273 590.697 688.652 589.311C690.031 587.925 691.669 586.825 693.473 586.072C695.277 585.319 697.212 584.93 699.167 584.925Z"
            fill="#2F2E41"
          />
          <path
            id="Vector 22"
            d="M749.11 586.532L772.754 586.473L772.791 601.36L734.261 601.456C734.256 599.501 734.636 597.564 735.379 595.756C736.123 593.948 737.215 592.305 738.594 590.919C739.973 589.533 741.612 588.432 743.416 587.68C745.22 586.927 747.155 586.537 749.11 586.532Z"
            fill="#2F2E41"
          />
          <path
            id="Vector 23"
            d="M747.906 276.534C761.471 276.534 772.467 265.538 772.467 251.973C772.467 238.409 761.471 227.412 747.906 227.412C734.341 227.412 723.345 238.409 723.345 251.973C723.345 265.538 734.341 276.534 747.906 276.534Z"
            fill="#FFB8B8"
          />
          <path
            id="Vector 24"
            d="M802.184 312.722C794.711 300.997 771.249 273.189 736.337 289.151C733.587 290.423 731.291 292.506 729.759 295.122C728.228 297.737 727.533 300.758 727.77 303.78C729.459 326.079 731.591 382.893 713.406 416.891C713.406 416.891 766.406 432.891 795.406 417.891C795.406 417.891 785.406 350.891 795.406 337.891C798.74 333.736 801.441 329.111 803.419 324.164C804.113 322.292 804.363 320.284 804.149 318.298C803.935 316.312 803.262 314.404 802.184 312.722Z"
            fill="#3F3D56"
          />
          <path
            id="Vector 25"
            d="M792.406 311.891L802.219 315.98C802.873 316.252 803.435 316.705 803.84 317.285C804.244 317.866 804.475 318.55 804.504 319.257L805.406 340.891L783.406 338.891L792.406 311.891Z"
            fill="#3F3D56"
          />
          <path
            id="Vector 26"
            d="M717.406 414.891C717.406 414.891 695.406 455.891 699.406 493.891L730.406 498.891L752.406 445.891L747.406 499.891L779.406 500.891C779.406 500.891 800.406 422.891 793.406 415.891L717.406 414.891Z"
            fill="#2F2E41"
          />
          <g id="boy-hand">
            <path
              id="Vector 27"
              d="M649.236 457.451C641.694 457.451 634.32 455.214 628.048 451.023C621.776 446.833 616.888 440.877 614.002 433.908C611.115 426.939 610.36 419.271 611.831 411.873C613.303 404.475 616.935 397.679 622.269 392.345C627.603 387.012 634.398 383.379 641.796 381.908C649.194 380.436 656.862 381.192 663.831 384.078C670.8 386.965 676.756 391.853 680.947 398.125C685.138 404.396 687.374 411.77 687.374 419.313C687.363 429.424 683.341 439.118 676.191 446.268C669.042 453.418 659.348 457.439 649.236 457.451ZM649.236 383.805C642.214 383.805 635.349 385.888 629.509 389.789C623.67 393.691 619.119 399.237 616.432 405.725C613.744 412.213 613.041 419.352 614.411 426.24C615.781 433.128 619.163 439.455 624.129 444.421C629.095 449.387 635.421 452.768 642.309 454.138C649.197 455.509 656.336 454.805 662.825 452.118C669.313 449.43 674.858 444.879 678.76 439.04C682.662 433.201 684.744 426.336 684.744 419.313C684.733 409.899 680.989 400.874 674.332 394.217C667.676 387.56 658.65 383.816 649.236 383.805Z"
              fill="#3F3D56"
            />
            <path
              id="Vector 28"
              d="M665.693 434.594C664.297 434.595 662.932 434.182 661.771 433.407C660.609 432.633 659.704 431.531 659.169 430.242C658.634 428.952 658.493 427.533 658.765 426.164C659.036 424.795 659.707 423.537 660.694 422.549C661.68 421.561 662.937 420.888 664.306 420.615C665.675 420.341 667.094 420.48 668.384 421.014C669.674 421.547 670.777 422.451 671.554 423.611C672.33 424.771 672.745 426.135 672.746 427.531C672.746 429.403 672.004 431.198 670.681 432.523C669.358 433.847 667.564 434.592 665.693 434.594ZM649.236 434.594C647.366 434.592 645.574 433.848 644.251 432.526C642.929 431.204 642.185 429.411 642.183 427.541V411.085C642.186 409.216 642.93 407.424 644.252 406.103C645.575 404.782 647.367 404.04 649.237 404.04C651.106 404.04 652.898 404.782 654.221 406.103C655.543 407.424 656.287 409.216 656.29 411.085V427.541C656.288 429.411 655.544 431.204 654.221 432.526C652.899 433.848 651.106 434.592 649.236 434.594ZM632.785 434.594C630.914 434.592 629.12 433.848 627.797 432.524C626.474 431.201 625.729 429.407 625.727 427.536V420.961C625.727 419.089 626.471 417.294 627.794 415.97C629.118 414.647 630.913 413.903 632.785 413.903C634.657 413.903 636.452 414.647 637.776 415.97C639.1 417.294 639.843 419.089 639.843 420.961V427.536C639.841 429.407 639.097 431.201 637.774 432.524C636.45 433.848 634.656 434.592 632.785 434.594Z"
              fill="#5B1B76"
            />
            <path
              id="Vector 29"
              d="M686.16 418.55C687.561 418.336 688.897 417.817 690.075 417.031C691.254 416.244 692.245 415.209 692.98 413.998C693.715 412.786 694.175 411.429 694.329 410.02C694.482 408.612 694.325 407.187 693.868 405.846L738.497 321.716L718.513 315.023L680.88 399.934C678.678 400.85 676.889 402.543 675.851 404.691C674.814 406.839 674.6 409.292 675.251 411.587C675.902 413.882 677.372 415.858 679.383 417.141C681.393 418.425 683.805 418.926 686.16 418.55Z"
              fill="#FFB8B8"
            />
            <path
              id="Vector 30"
              d="M744.59 298.941L736.324 295.243C736.324 295.243 729.315 290.898 723.103 300.459C716.89 310.019 710.179 330.971 710.179 330.971L737.15 338.56L744.59 298.941Z"
              fill="#3F3D56"
            />
          </g>
          <path
            id="Vector 31"
            d="M751.84 263.798C752.722 260.736 752.468 256.593 749.499 255.435C747.952 254.831 746.227 255.307 744.575 255.476C741.343 255.794 738.109 254.903 735.495 252.977C732.424 250.694 730.528 247.205 728.727 243.83L726.003 238.724C725.396 237.66 724.879 236.547 724.457 235.397C722.941 230.89 724.587 225.671 727.956 222.315C731.477 219.04 736.11 217.223 740.919 217.233C745.69 217.267 750.404 218.277 754.77 220.2C762.531 223.364 769.561 228.086 775.423 234.075C779.255 237.996 782.744 243.102 781.943 248.525C781.32 252.747 778.243 256.127 775.308 259.225L764.663 270.462C762.778 272.452 760.764 274.523 758.132 275.288C755.499 276.053 752.108 274.929 751.391 272.284C751.391 272.284 750.958 266.86 751.84 263.798Z"
            fill="#2F2E41"
          />
          <g id="for-circle1">
            <path
              id="Vector 32"
              d="M257.299 215.308C249.756 215.308 242.383 213.071 236.111 208.881C229.839 204.69 224.951 198.734 222.064 191.765C219.178 184.796 218.422 177.128 219.894 169.73C221.366 162.332 224.998 155.536 230.332 150.203C235.665 144.869 242.461 141.237 249.859 139.765C257.257 138.293 264.925 139.049 271.894 141.935C278.863 144.822 284.819 149.71 289.01 155.982C293.2 162.254 295.437 169.627 295.437 177.17C295.426 187.281 291.404 196.975 284.254 204.125C277.104 211.275 267.41 215.297 257.299 215.308ZM257.299 141.662C250.276 141.662 243.411 143.745 237.572 147.647C231.733 151.548 227.182 157.094 224.494 163.582C221.807 170.07 221.104 177.21 222.474 184.097C223.844 190.985 227.226 197.312 232.191 202.278C237.157 207.244 243.484 210.626 250.372 211.996C257.26 213.366 264.399 212.663 270.887 209.975C277.376 207.288 282.921 202.736 286.823 196.897C290.724 191.058 292.807 184.193 292.807 177.17C292.796 167.756 289.052 158.731 282.395 152.074C275.738 145.418 266.713 141.673 257.299 141.662Z"
              fill="#3F3D56"
            />
            <path
              id="Vector 33"
              d="M272.113 197.387C271.115 197.388 270.157 196.991 269.45 196.286C268.744 195.58 268.346 194.623 268.344 193.625V172.226C268.345 171.229 268.742 170.272 269.447 169.567C270.153 168.861 271.109 168.465 272.107 168.464H272.113C273.111 168.465 274.067 168.861 274.773 169.567C275.478 170.272 275.875 171.229 275.876 172.226V193.625C275.875 194.622 275.478 195.579 274.773 196.284C274.067 196.989 273.111 197.386 272.113 197.387ZM262.289 197.387C261.791 197.392 261.297 197.299 260.835 197.112C260.373 196.926 259.953 196.649 259.598 196.3C259.244 195.95 258.962 195.534 258.768 195.075C258.575 194.615 258.475 194.123 258.473 193.625V182.098C258.474 181.1 258.871 180.144 259.576 179.439C260.281 178.733 261.238 178.337 262.235 178.336H262.289C263.287 178.337 264.243 178.733 264.948 179.439C265.654 180.144 266.051 181.1 266.052 182.098V193.625C266.051 194.622 265.654 195.579 264.948 196.284C264.243 196.989 263.287 197.386 262.289 197.387ZM252.37 197.387C251.372 197.388 250.414 196.991 249.707 196.286C249 195.58 248.602 194.623 248.601 193.625V160.716C248.602 159.718 248.999 158.762 249.704 158.056C250.41 157.351 251.366 156.954 252.363 156.953H252.37C253.368 156.954 254.324 157.351 255.029 158.056C255.735 158.762 256.132 159.718 256.133 160.716V193.625C256.132 194.622 255.735 195.579 255.029 196.284C254.324 196.989 253.368 197.386 252.37 197.387ZM242.489 197.387C241.994 197.388 241.504 197.291 241.047 197.102C240.59 196.913 240.175 196.635 239.825 196.286C239.475 195.936 239.197 195.521 239.008 195.064C238.819 194.606 238.722 194.117 238.722 193.622L238.727 188.679C238.727 187.681 239.124 186.724 239.83 186.019C240.536 185.314 241.493 184.918 242.491 184.918C243.488 184.919 244.445 185.316 245.15 186.022C245.856 186.727 246.252 187.685 246.251 188.682V193.625C246.25 194.622 245.853 195.579 245.148 196.284C244.443 196.989 243.486 197.386 242.489 197.387Z"
              fill="#5B1B76"
            />
          </g>
          <g id="for-circle2">
            <path
              id="Vector 34"
              d="M419.367 215.308C411.824 215.308 404.45 213.071 398.178 208.881C391.907 204.69 387.019 198.734 384.132 191.765C381.245 184.796 380.49 177.128 381.962 169.73C383.433 162.332 387.065 155.536 392.399 150.203C397.733 144.869 404.528 141.237 411.926 139.765C419.324 138.293 426.993 139.049 433.962 141.935C440.93 144.822 446.887 149.71 451.077 155.982C455.268 162.254 457.505 169.627 457.505 177.17C457.493 187.281 453.472 196.975 446.322 204.125C439.172 211.275 429.478 215.297 419.367 215.308ZM419.367 141.662C412.344 141.662 405.479 143.745 399.64 147.647C393.801 151.548 389.249 157.094 386.562 163.582C383.874 170.07 383.171 177.21 384.541 184.097C385.911 190.985 389.293 197.312 394.259 202.278C399.225 207.244 405.552 210.626 412.44 211.996C419.327 213.366 426.467 212.663 432.955 209.975C439.443 207.288 444.989 202.736 448.89 196.897C452.792 191.058 454.874 184.193 454.874 177.17C454.864 167.756 451.119 158.731 444.463 152.074C437.806 145.418 428.781 141.673 419.367 141.662Z"
              fill="#3F3D56"
            />
            <path
              id="Vector 35"
              d="M399.105 190.995C398.557 190.995 398.021 190.832 397.565 190.528C397.109 190.223 396.754 189.791 396.544 189.285C396.334 188.778 396.279 188.221 396.385 187.683C396.491 187.146 396.754 186.652 397.141 186.263L411.233 172.132C411.452 171.911 411.713 171.736 412.001 171.616C412.289 171.497 412.597 171.436 412.909 171.437H412.91C413.221 171.436 413.529 171.497 413.817 171.616C414.104 171.735 414.365 171.909 414.585 172.13L421.12 178.665C421.252 178.798 421.408 178.903 421.581 178.974C421.753 179.046 421.938 179.082 422.125 179.082H422.126C422.313 179.082 422.498 179.046 422.67 178.974C422.842 178.903 422.999 178.798 423.131 178.665L432.358 169.422C432.402 169.378 432.436 169.326 432.46 169.269C432.484 169.211 432.496 169.15 432.496 169.087C432.496 169.025 432.484 168.964 432.46 168.906C432.436 168.849 432.401 168.797 432.357 168.753L429.372 165.772C429.173 165.574 429.037 165.32 428.982 165.045C428.927 164.769 428.955 164.483 429.062 164.223C429.17 163.963 429.352 163.741 429.586 163.585C429.819 163.429 430.094 163.345 430.375 163.345H440.979C441.356 163.346 441.717 163.496 441.983 163.762C442.25 164.029 442.4 164.39 442.4 164.767V175.364C442.4 175.645 442.317 175.919 442.161 176.153C442.005 176.387 441.783 176.569 441.523 176.677C441.263 176.784 440.978 176.813 440.702 176.758C440.426 176.703 440.173 176.568 439.974 176.369L436.984 173.379C436.94 173.335 436.888 173.3 436.831 173.276C436.773 173.252 436.712 173.24 436.649 173.24C436.587 173.24 436.526 173.252 436.468 173.276C436.411 173.3 436.359 173.335 436.315 173.379L423.82 185.865C423.6 186.085 423.339 186.26 423.052 186.379C422.765 186.498 422.457 186.559 422.145 186.558C421.834 186.559 421.526 186.498 421.238 186.379C420.951 186.26 420.69 186.085 420.471 185.864L413.932 179.326C413.661 179.067 413.301 178.923 412.927 178.923C412.553 178.923 412.193 179.067 411.922 179.326L401.066 190.182C400.809 190.441 400.503 190.645 400.167 190.785C399.83 190.925 399.469 190.996 399.105 190.995Z"
              fill="#5B1B76"
            />
          </g>
          <path
            id="Vector 36"
            d="M419.367 315.308C411.824 315.308 404.45 313.071 398.178 308.881C391.907 304.69 387.019 298.734 384.132 291.765C381.245 284.796 380.49 277.128 381.962 269.73C383.433 262.332 387.065 255.536 392.399 250.203C397.733 244.869 404.528 241.237 411.926 239.765C419.324 238.293 426.993 239.049 433.962 241.935C440.93 244.822 446.887 249.71 451.077 255.982C455.268 262.254 457.505 269.627 457.505 277.17C457.493 287.281 453.472 296.975 446.322 304.125C439.172 311.275 429.478 315.297 419.367 315.308Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector 37"
            d="M577.367 212.308C569.824 212.308 562.45 210.071 556.178 205.881C549.907 201.69 545.019 195.734 542.132 188.765C539.245 181.796 538.49 174.128 539.962 166.73C541.433 159.332 545.065 152.536 550.399 147.203C555.733 141.869 562.528 138.237 569.926 136.765C577.324 135.293 584.993 136.049 591.962 138.935C598.93 141.822 604.887 146.71 609.077 152.982C613.268 159.254 615.505 166.627 615.505 174.17C615.493 184.281 611.472 193.975 604.322 201.125C597.172 208.275 587.478 212.297 577.367 212.308Z"
            fill="#E6E6E6"
          />
          <g id="for-circle4">
            <path
              id="Vector 38"
              d="M242.83 241.892C240.414 241.408 237.955 241.166 235.49 241.172C225.684 241.161 216.25 244.925 209.143 251.682C202.036 258.439 197.802 267.672 197.317 277.466C196.833 287.26 200.136 296.865 206.541 304.291C212.947 311.716 221.964 316.392 231.723 317.349C241.483 318.306 251.237 315.472 258.963 309.433C266.689 303.393 271.795 294.613 273.223 284.911C274.651 275.21 272.292 265.33 266.633 257.321C260.975 249.312 252.452 243.787 242.83 241.892ZM245.41 313.412C240.526 314.832 235.394 315.187 230.361 314.452C225.327 313.717 220.511 311.909 216.237 309.151C211.963 306.393 208.331 302.749 205.588 298.466C202.844 294.182 201.053 289.359 200.336 284.324C199.618 279.288 199.99 274.157 201.428 269.277C202.866 264.398 205.335 259.884 208.668 256.042C212.001 252.2 216.121 249.119 220.749 247.007C225.376 244.895 230.403 243.802 235.49 243.802C236.54 243.802 237.58 243.852 238.61 243.932C246.86 244.661 254.596 248.25 260.479 254.078C266.363 259.907 270.025 267.608 270.831 275.851C271.638 284.093 269.539 292.359 264.897 299.217C260.255 306.076 253.362 311.097 245.41 313.412Z"
              fill="#3F3D56"
            />
            <path
              id="Vector 39"
              d="M257.696 272.598C256.436 267.073 253.034 262.274 248.238 259.254C243.442 256.235 237.644 255.241 232.117 256.493C231.577 256.616 231.018 256.768 230.525 256.919L235.622 279.315L258.018 274.228C257.924 273.707 257.819 273.138 257.696 272.598Z"
              fill="#5B1B76"
            />
            <path
              id="Vector 40"
              d="M255.527 278.159L233.121 283.247L228.034 260.851C224.127 262.059 220.649 264.363 218.012 267.488C215.375 270.613 213.689 274.429 213.155 278.483C212.621 282.537 213.26 286.66 214.997 290.362C216.734 294.063 219.497 297.19 222.956 299.369C226.416 301.549 230.429 302.691 234.518 302.66C238.607 302.628 242.601 301.425 246.027 299.193C249.453 296.96 252.167 293.792 253.848 290.064C255.528 286.337 256.104 282.205 255.508 278.159H255.527Z"
              fill="#5B1B76"
            />
          </g>
          <g id="for-circle3">
            <path
              id="Vector 41"
              d="M577.035 317.451C569.492 317.451 562.119 315.214 555.847 311.024C549.575 306.833 544.687 300.877 541.801 293.908C538.914 286.939 538.159 279.271 539.63 271.873C541.102 264.475 544.734 257.679 550.068 252.345C555.402 247.012 562.197 243.379 569.595 241.908C576.993 240.436 584.661 241.192 591.63 244.078C598.599 246.965 604.555 251.853 608.746 258.125C612.937 264.396 615.173 271.77 615.173 279.313C615.162 289.424 611.14 299.118 603.99 306.268C596.841 313.418 587.147 317.44 577.035 317.451ZM577.035 243.805C570.013 243.805 563.148 245.888 557.308 249.789C551.469 253.691 546.918 259.237 544.231 265.725C541.543 272.213 540.84 279.352 542.21 286.24C543.58 293.128 546.962 299.455 551.928 304.421C556.894 309.387 563.22 312.768 570.108 314.138C576.996 315.509 584.135 314.805 590.624 312.118C597.112 309.43 602.657 304.879 606.559 299.04C610.461 293.201 612.543 286.336 612.543 279.313C612.532 269.899 608.788 260.874 602.131 254.217C595.475 247.56 586.449 243.816 577.035 243.805Z"
              fill="#3F3D56"
            />
            <path
              id="Vector 42"
              d="M591.035 295.313H563.035C562.24 295.313 561.477 294.997 560.914 294.434C560.352 293.872 560.035 293.109 560.035 292.313C560.035 291.517 560.352 290.754 560.914 290.192C561.477 289.629 562.24 289.313 563.035 289.313H591.035C591.831 289.313 592.594 289.629 593.157 290.192C593.719 290.754 594.035 291.517 594.035 292.313C594.035 293.109 593.719 293.872 593.157 294.434C592.594 294.997 591.831 295.313 591.035 295.313Z"
              fill="#5B1B76"
            />
            <path
              id="Vector 43"
              d="M591.035 282.313H563.035C562.24 282.313 561.477 281.997 560.914 281.434C560.352 280.872 560.035 280.109 560.035 279.313C560.035 278.517 560.352 277.754 560.914 277.192C561.477 276.629 562.24 276.313 563.035 276.313H591.035C591.831 276.313 592.594 276.629 593.157 277.192C593.719 277.754 594.035 278.517 594.035 279.313C594.035 280.109 593.719 280.872 593.157 281.434C592.594 281.997 591.831 282.313 591.035 282.313Z"
              fill="#5B1B76"
            />
            <path
              id="Vector 44"
              d="M591.035 269.313H563.035C562.24 269.313 561.477 268.997 560.914 268.434C560.352 267.872 560.035 267.109 560.035 266.313C560.035 265.517 560.352 264.754 560.914 264.192C561.477 263.629 562.24 263.313 563.035 263.313H591.035C591.831 263.313 592.594 263.629 593.157 264.192C593.719 264.754 594.035 265.517 594.035 266.313C594.035 267.109 593.719 267.872 593.157 268.434C592.594 268.997 591.831 269.313 591.035 269.313Z"
              fill="#5B1B76"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Data;

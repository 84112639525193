import React, { useState, useEffect } from "react";
import Navb from "./Components/Musts/Navb";
import WhoWeAre from "./Components/LandingPage/WhoWeAre";
import Intro from "./Components/LandingPage/Intro";
import WhatWeDeal from "./Components/LandingPage/WhatWeDeal";
import ScrollAnimation from "react-animate-on-scroll";
import FooterLanding from "./Components/LandingPage/FooterLanding";
import { Spinner } from "react-bootstrap";
import "./App.css";

const Routes = () => {
  // const imagePath = process.env.PUBLIC_URL + "/assets/images/";

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setLoading(false);
    }, 500);
  });

  return (
    <>
      {loading ? (
        <Spinner
          animation="grow"
          style={{
            color: "#9533be",
            textAlign: "center",
            margin: 0,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      ) : (
        <div
          className="main"
          // style={{
          // backgroundImage: `url(${imagePath}check.png)`,
          // backgroundSize: "auto 100%",
          // height: "100%",
          // width: "90%",
          // marginLeft: "auto",
          // marginRight: "auto",
          // }}
        >
          <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
            {/* <div className="nav-in-main"> */}
            <Navb dis="?" />
            {/* </div> */}

            <Intro />
            <WhatWeDeal />
            <WhoWeAre />
            <FooterLanding />
          </ScrollAnimation>
        </div>
      )}
    </>
  );
};

export default Routes;

import React, { useEffect } from "react";

const ErrorPage = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#f4f2fb";
    document.body.style.fontFamily = "Jost";
  });

  return (
    <>
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
          height: "100vh",
          width: "100%",
          position: "relative",
          border: "3px solid green",
        }}
      >
        <div
          style={{
            margin: 0,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: `translate(-50%, -50%)`,
          }}
        >
          <img
            alt=""
            src={`${process.env.PUBLIC_URL}assets/images/404.png`}
            style={{ width: "60%", height: "60%", marginTop: "20px" }}
          />
          <br />
          {/* <br /> */}
          {/* <br /> */}
          <a href="/">
            <button className="back-btn-port" style={{ marginTop: "40px" }}>
              Go back to the Main Page
            </button>
          </a>
          {/* <hr
            className="back-hr"
            style={{
              background: "#9533be",
              width: "130px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 0,
            }}
          /> */}
        </div>
      </div>
    </>
  );
};

export default ErrorPage;

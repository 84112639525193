import React, { useState } from "react";
import { Zoom, Slide, Fade } from "react-reveal";
import TextCard from "./TextCard";
import BigModal from "./BigModal";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
// import SideBar from "./SideBar";

const PortfolioProducts = (props) => {
  // const [textMore, setTextMore] = useState("More Information");
  // const [img, setImg] = useState(0);
  // const [snapText, setSnapText] = useState("Mobile");
  // const [disForFirstWeb, setDisForFirstWeb] = useState("block");
  // const [disForFirstMob, setDisForFirstMob] = useState("none");
  // const [disForImgTab, setDisForImgTab] = useState("block");
  // const [disForInfoTab, setDisForInfoTab] = useState("none");
  const [disForProj1, setDisForProj1] = useState("block");
  const [disForProjDet1, setDisForProjDet1] = useState("none");
  const [disForProj2, setDisForProj2] = useState("block");
  const [disForProjDet2, setDisForProjDet2] = useState("none");
  const [disForProj3, setDisForProj3] = useState("block");
  const [disForProjDet3, setDisForProjDet3] = useState("none");
  const [disForProj4, setDisForProj4] = useState("block");
  const [disForProjDet4, setDisForProjDet4] = useState("none");
  const [disForProj5, setDisForProj5] = useState("block");
  const [disForProjDet5, setDisForProjDet5] = useState("none");
  const [disForProj6, setDisForProj6] = useState("block");
  const [disForProjDet6, setDisForProjDet6] = useState("none");

  const [animateWebProj1, setAnimateWebProj1] = useState(false);
  const [animateWebProj2, setAnimateWebProj2] = useState(false);
  const [animateWebProj3, setAnimateWebProj3] = useState(false);
  const [animateWebProj4, setAnimateWebProj4] = useState(false);
  const [webDevAnim, setWebDevAnim] = useState(true);
  const [mobDevAnim, setMobDevAnim] = useState(true);
  const [disForWebProj, setDisForWebProj] = useState(true);
  const [disForMobProj, setDisForMobProj] = useState(isMobile ? "none" : true);
  // const [animateWebProj5, setAnimateWebProj5] = useState(false);

  const [animateMobProj1, setAnimateMobProj1] = useState(false);
  const [animateMobProj2, setAnimateMobProj2] = useState(false);
  // const [animateMobProj3, setAnimateMobProj3] = useState(false);

  const dairypal = [
    process.env.PUBLIC_URL + `/assets/images/dairypal-web/main.png`,
    process.env.PUBLIC_URL + `/assets/images/dairypal-web/login.png`,
    process.env.PUBLIC_URL + `/assets/images/dairypal-web/home.png`,
  ];

  const dairypalMob = [
    process.env.PUBLIC_URL + `/assets/images/dairypal-mob/main.png`,
    process.env.PUBLIC_URL + `/assets/images/dairypal-mob/home.png`,
    process.env.PUBLIC_URL + `/assets/images/dairypal-mob/store.png`,
  ];

  const coignitive = [
    process.env.PUBLIC_URL + `/assets/images/coignitive-agent/main.png`,
    // process.env.PUBLIC_URL + `/assets/images/coignitive-agent/vid.mp4`,
  ];

  const frostie = [
    process.env.PUBLIC_URL + `/assets/images/frostie-vr/main.png`,
    process.env.PUBLIC_URL + `/assets/images/frostie-vr/two.png`,
    process.env.PUBLIC_URL + `/assets/images/frostie-vr/three.png`,
    process.env.PUBLIC_URL + `/assets/images/frostie-vr/four.png`,
  ];

  const jozeko = [
    process.env.PUBLIC_URL + `/assets/images/jozeko/first.png`,
    process.env.PUBLIC_URL + `/assets/images/jozeko/two.png`,
    process.env.PUBLIC_URL + `/assets/images/jozeko/three.png`,
    process.env.PUBLIC_URL + `/assets/images/jozeko/four.png`,
  ];

  const aceso = [
    process.env.PUBLIC_URL + `/assets/images/aceso/first.png`,
    process.env.PUBLIC_URL + `/assets/images/aceso/second.png`,
    process.env.PUBLIC_URL + `/assets/images/aceso/third.png`,
    process.env.PUBLIC_URL + `/assets/images/aceso/fourth.png`,
  ];

  const broadband = [
    process.env.PUBLIC_URL + `/assets/images/broadband/main.png`,
  ];

  const myCallback = (dataFromChild) => {
    setDisForProj1(dataFromChild);
    //console.log("Hello checking state" + clr);
  };

  const myCallback2 = (dataFromChild2) => {
    setDisForProjDet1(dataFromChild2);
    //console.log("Hello checking state" + clr);
  };

  const myCallback3 = (dataFromChild3) => {
    setDisForProj2(dataFromChild3);
    //console.log("Hello checking state" + clr);
  };

  const myCallback4 = (dataFromChild4) => {
    setDisForProjDet2(dataFromChild4);
    //console.log("Hello checking state" + clr);
  };

  const myCallback5 = (dataFromChild5) => {
    setDisForProj3(dataFromChild5);
    //console.log("Hello checking state" + clr);
  };

  const myCallback6 = (dataFromChild6) => {
    setDisForProjDet3(dataFromChild6);
    //console.log("Hello checking state" + clr);
  };

  const myCallback7 = (dataFromChild7) => {
    setDisForProj4(dataFromChild7);
    //console.log("Hello checking state" + clr);
  };

  const myCallback8 = (dataFromChild8) => {
    setDisForProjDet4(dataFromChild8);
    //console.log("Hello checking state" + clr);
  };

  const myCallback9 = (dataFromChild9) => {
    setDisForProj5(dataFromChild9);
    //console.log("Hello checking state" + clr);
  };

  const myCallback10 = (dataFromChild10) => {
    setDisForProjDet5(dataFromChild10);
    //console.log("Hello checking state" + clr);
  };

  const myCallback11 = (dataFromChild11) => {
    setDisForProj6(dataFromChild11);
    //console.log("Hello checking state" + clr);
  };

  const myCallback12 = (dataFromChild12) => {
    setDisForProjDet6(dataFromChild12);
    //console.log("Hello checking state" + clr);
  };

  const myCB2 = (dataFromC) => {
    props.callbackFromP(dataFromC);
    console.log("Here II ", dataFromC);
  };

  return (
    <>
      <div className="prod-main" id="our-products">
        <h1 className="tech-h">
          <span className="our-head">
            <Zoom left cascade>
              OUR
            </Zoom>
          </span>
          <span className="prod-head">
            <Zoom left cascade>
              P R O D U C T S
            </Zoom>
          </span>
        </h1>
        <div className="tech-p2">
          <div className="prod-text">
            <div className="prod-h">
              <h1
                onClick={() => {
                  if (isMobile) {
                    setWebDevAnim(true);
                    setMobDevAnim(false);
                    setDisForWebProj("block");
                    setDisForMobProj("none");
                  }
                }}
                className={classNames("fr-h4", webDevAnim && "fr-h4-scale")}
              >
                WEB DEVELOPMENT
              </h1>
              <div style={{ display: disForWebProj }}>
                <Fade>
                  <a href="#dairypal">
                    <h2
                      // className="fr-h"
                      className={classNames(
                        "fr-h3",
                        animateWebProj1 && "fr-h-scale"
                      )}
                      // id="dairypal"
                      style={{
                        borderColor: "transparent",
                        backgroundColor: "transparent",
                        width: "100%",
                        // color: btnClr,
                      }}
                      onClick={() => {
                        // setDisForDesc("block");
                        // setDisForTech("none");
                        // setDisForWebSn("none");
                        // setDisForMobSn("none");
                        // setDisForPlat("none");
                        setAnimateWebProj1(true);
                        setAnimateWebProj2(false);
                        setAnimateWebProj3(false);
                        setAnimateWebProj4(false);
                        // setAnimateWebProj5(false);
                      }}
                    >
                      DAIRYPAL
                    </h2>
                  </a>
                  <a href="#coagent">
                    <h2
                      // className="fr-h"
                      className={classNames(
                        "fr-h3",
                        animateWebProj2 && "fr-h-scale"
                      )}
                      style={{
                        borderColor: "transparent",
                        backgroundColor: "transparent",
                        width: "100%",
                        // color: btnClr,
                      }}
                      onClick={() => {
                        // setDisForDesc("block");
                        // setDisForTech("none");
                        // setDisForWebSn("none");
                        // setDisForMobSn("none");
                        // setDisForPlat("none");
                        setAnimateWebProj1(false);
                        setAnimateWebProj2(true);
                        setAnimateWebProj3(false);
                        setAnimateWebProj4(false);
                        // setAnimateWebProj5(false);
                      }}
                    >
                      COIGNITIVE AGENT
                    </h2>
                  </a>
                  <a href="#frostievr">
                    <h2
                      // className="fr-h"
                      className={classNames(
                        "fr-h3",
                        animateWebProj3 && "fr-h-scale"
                      )}
                      style={{
                        borderColor: "transparent",
                        backgroundColor: "transparent",
                        width: "100%",
                        // color: btnClr,
                      }}
                      onClick={() => {
                        // setDisForDesc("block");
                        // setDisForTech("none");
                        // setDisForWebSn("none");
                        // setDisForMobSn("none");
                        // setDisForPlat("none");
                        setAnimateWebProj1(false);
                        setAnimateWebProj2(false);
                        setAnimateWebProj3(true);
                        setAnimateWebProj4(false);
                        // setAnimateWebProj5(false);
                      }}
                    >
                      FROSTIE-VR
                    </h2>
                  </a>
                  <a href="#broadband">
                    <h2
                      // className="fr-h"
                      className={classNames(
                        "fr-h3",
                        animateWebProj4 && "fr-h-scale"
                      )}
                      style={{
                        borderColor: "transparent",
                        backgroundColor: "transparent",
                        width: "100%",
                        // color: btnClr,
                      }}
                      onClick={() => {
                        // setDisForDesc("block");
                        // setDisForTech("none");
                        // setDisForWebSn("none");
                        // setDisForMobSn("none");
                        // setDisForPlat("none");
                        setAnimateWebProj1(false);
                        setAnimateWebProj2(false);
                        setAnimateWebProj3(false);
                        setAnimateWebProj4(true);
                        // setAnimateWebProj5(false);
                      }}
                    >
                      BROADBAND
                    </h2>
                  </a>
                </Fade>
              </div>
            </div>

            <div className="prod-h3">
              <h1
                onClick={() => {
                  if (isMobile) {
                    setMobDevAnim(true);
                    setWebDevAnim(false);
                    setDisForWebProj("none");
                    setDisForMobProj("block");
                  }
                }}
                className={classNames("fr-h4", mobDevAnim && "fr-h4-scale")}
              >
                MOBILE DEVELOPMENT
              </h1>
              <div style={{ display: disForMobProj }}>
                <Fade>
                  <a href="#dairypal">
                    <h2
                      // className="fr-h"
                      className={classNames(
                        "fr-h3",
                        animateMobProj1 && "fr-h-scale"
                      )}
                      style={{
                        borderColor: "transparent",
                        backgroundColor: "transparent",
                        width: "100%",
                        // color: btnClr,
                      }}
                      onClick={() => {
                        // setDisForDesc("block");
                        // setDisForTech("none");
                        // setDisForWebSn("none");
                        // setDisForMobSn("none");
                        // setDisForPlat("none");
                        setAnimateMobProj1(true);
                        setAnimateMobProj2(false);
                        // setAnimateMobProj3(false);
                        // setAnimateMobProj4(false);
                        // setAnimateMobProj5(false);
                      }}
                    >
                      DAIRYPAL
                    </h2>
                  </a>
                  <a href="#jozeko">
                    <h2
                      // className="fr-h"
                      className={classNames(
                        "fr-h3",
                        animateMobProj2 && "fr-h-scale"
                      )}
                      style={{
                        borderColor: "transparent",
                        backgroundColor: "transparent",
                        width: "100%",
                        // color: btnClr,
                      }}
                      onClick={() => {
                        // setDisForDesc("block");
                        // setDisForTech("none");
                        // setDisForWebSn("none");
                        // setDisForMobSn("none");
                        // setDisForPlat("none");
                        setAnimateMobProj1(false);
                        setAnimateMobProj2(true);
                        // setAnimateMobProj3(false);
                        // setAnimateMobProj4(false);
                        // setAnimateMobProj5(false);
                      }}
                    >
                      JOZEKO
                    </h2>
                  </a>
                  <a href="#aceso">
                    <h2
                      // className="fr-h"
                      className={classNames(
                        "fr-h3",
                        animateMobProj2 && "fr-h-scale"
                      )}
                      style={{
                        borderColor: "transparent",
                        backgroundColor: "transparent",
                        width: "100%",
                        // color: btnClr,
                      }}
                      onClick={() => {
                        // setDisForDesc("block");
                        // setDisForTech("none");
                        // setDisForWebSn("none");
                        // setDisForMobSn("none");
                        // setDisForPlat("none");
                        setAnimateMobProj1(false);
                        setAnimateMobProj2(true);
                        // setAnimateMobProj3(false);
                        // setAnimateMobProj4(false);
                        // setAnimateMobProj5(false);
                      }}
                    >
                      ACESO
                    </h2>
                  </a>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="prod-p" id="dairypal">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/dairypal-web/main.png`}
          alt=""
          className="first-proj-img"
        ></img>

        <div className="first-project">
          <div>
            <div style={{ display: disForProj1 }}>
              <Slide left>
                <TextCard
                  prjName="DairyPal"
                  prjDesc="A Delivery Application"
                  btnText="More Information"
                  callbackFromParent={myCallback}
                  callbackFromParent2={myCallback2}
                  callbackFromParent3={myCallback3}
                  callbackFromParent4={myCallback4}
                  callbackFromParent5={myCallback5}
                  callbackFromParent6={myCallback6}
                  callbackFromParent7={myCallback7}
                  callbackFromParent8={myCallback8}
                  callbackFromParent9={myCallback9}
                  callbackFromParent10={myCallback10}
                  callbackFromParent11={myCallback11}
                  callbackFromParent12={myCallback12}
                  accentClr="#ffa500"
                  backAccentClr="#fff2da"
                  btnClr="#fff"
                />
              </Slide>
            </div>
            <div style={{ display: disForProjDet1 }}>
              <Fade right>
                <BigModal
                  prjName="DairyPal"
                  prjDesc="Your dairy products selling made easy! Sale, Purchase and Deliver a one stop shop for all dairy related products. With DairyPal if you are a farm owner, you can sell your dairy products directly to the customers rather then dealing with middle men and commission agents. If you are a dairy product buyer sitting at home thinking about buying dairy products you are just a click away from ordering products at your door step. Lastly, the products will easily be delivered to a customer through a delivery boy."
                  btnText="Close"
                  callbackFromParent={myCallback}
                  callbackFromParent2={myCallback2}
                  callbackFromParent3={myCallback3}
                  callbackFromParent4={myCallback4}
                  callbackFromParent5={myCallback5}
                  callbackFromParent6={myCallback6}
                  callbackFromParent7={myCallback7}
                  callbackFromParent8={myCallback8}
                  callbackFromParent9={myCallback9}
                  callbackFromParent10={myCallback10}
                  callbackFromParent11={myCallback11}
                  callbackFromParent12={myCallback12}
                  mobile={true}
                  web={true}
                  techList={[
                    "react",
                    "bootstrap",
                    "css",
                    "javascript",
                    "node",
                    "mongo",
                  ]}
                  webSS={dairypal}
                  mobSS={dairypalMob}
                  accentClr="#ffa500"
                  backAccentClr="#fff2da"
                  btnClr="#fff"
                  myCB2={myCB2}
                />
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <div className="prod-p" id="coagent">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/coignitive-agent/main.png`}
          alt=""
          className="first-proj-img"
        ></img>

        <div className="first-project">
          <div>
            <div style={{ display: disForProj2 }}>
              <Slide left>
                <TextCard
                  prjName="Coignitive Agent"
                  prjDesc="An AI Powered Chatbot"
                  btnText="More Information"
                  callbackFromParent={myCallback}
                  callbackFromParent2={myCallback2}
                  callbackFromParent3={myCallback3}
                  callbackFromParent4={myCallback4}
                  callbackFromParent5={myCallback5}
                  callbackFromParent6={myCallback6}
                  callbackFromParent7={myCallback7}
                  callbackFromParent8={myCallback8}
                  callbackFromParent9={myCallback9}
                  callbackFromParent10={myCallback10}
                  callbackFromParent11={myCallback11}
                  callbackFromParent12={myCallback12}
                  accentClr="#fff"
                  backAccentClr="#3d3d3d"
                  btnClr="#fff"
                />
              </Slide>
            </div>
            <div style={{ display: disForProjDet2 }}>
              <Fade right>
                <BigModal
                  prjName="Coignitive Agent"
                  prjDesc="Enterprises are now looking at Artificial intelligence (AI) for automating processes that a few years ago would have been impossible to even contemplate. Customer Cognitive Agent is a machine learning and Artificial Intelligence based customer relations cognitive agent which helps enterprises and businesses to flourish through it’s data analytics and customer engagement. Enterprises and businesses can use this agent to cut marketing costs and keep in track with changing demands and patterns of their products in enterprise market through engaging customers and data analytics."
                  btnText="Close"
                  callbackFromParent={myCallback}
                  callbackFromParent2={myCallback2}
                  callbackFromParent3={myCallback3}
                  callbackFromParent4={myCallback4}
                  callbackFromParent5={myCallback5}
                  callbackFromParent6={myCallback6}
                  callbackFromParent7={myCallback7}
                  callbackFromParent8={myCallback8}
                  callbackFromParent9={myCallback9}
                  callbackFromParent10={myCallback10}
                  callbackFromParent11={myCallback11}
                  callbackFromParent12={myCallback12}
                  web={true}
                  techList={[
                    "react",
                    "bootstrap",
                    "css",
                    "javascript",
                    "node",
                    "python",
                    "mongo",
                    "sql",
                  ]}
                  webSS={coignitive}
                  accentClr="#fff"
                  backAccentClr="#3d3d3d"
                  btnClr="#a19f9f"
                  myCB2={myCB2}
                />
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <div className="prod-p" id="frostievr">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/frostie-vr/main.png`}
          alt=""
          className="first-proj-img"
        ></img>

        <div className="first-project">
          <div>
            <div style={{ display: disForProj3 }}>
              <Slide left>
                <TextCard
                  prjName="FrostieVR"
                  prjDesc="A 360VR Image Sharing Application"
                  btnText="More Information"
                  callbackFromParent={myCallback}
                  callbackFromParent2={myCallback2}
                  callbackFromParent3={myCallback3}
                  callbackFromParent4={myCallback4}
                  callbackFromParent5={myCallback5}
                  callbackFromParent6={myCallback6}
                  callbackFromParent7={myCallback7}
                  callbackFromParent8={myCallback8}
                  callbackFromParent9={myCallback9}
                  callbackFromParent10={myCallback10}
                  callbackFromParent11={myCallback11}
                  callbackFromParent12={myCallback12}
                  accentClr="#000"
                  backAccentClr="#e4e3e3"
                />
              </Slide>
            </div>
            <div style={{ display: disForProjDet3 }}>
              <Fade right>
                <BigModal
                  prjName="FrostieVR"
                  prjDesc="Your 360° content the way it deserves to be seen! Frostie-VR gives the smoothest and the most efficient 360° player on the web. You don’t just show images to your clients but give them an experience. From creating your own virtual toursto integrate content into your website Frostie-VR has it all. Our own editor allows you to adjust theimage settings as you want and with the subscription planning you can custom brand as you desire."
                  btnText="Close"
                  callbackFromParent={myCallback}
                  callbackFromParent2={myCallback2}
                  callbackFromParent3={myCallback3}
                  callbackFromParent4={myCallback4}
                  callbackFromParent5={myCallback5}
                  callbackFromParent6={myCallback6}
                  callbackFromParent7={myCallback7}
                  callbackFromParent8={myCallback8}
                  callbackFromParent9={myCallback9}
                  callbackFromParent10={myCallback10}
                  callbackFromParent11={myCallback11}
                  callbackFromParent12={myCallback12}
                  web={true}
                  techList={[
                    "react",
                    "bootstrap",
                    "css",
                    "javascript",
                    "node",
                    "sql",
                  ]}
                  webSS={frostie}
                  accentClr="#000"
                  backAccentClr="#e4e3e3"
                  btnClr="#e4e3e3"
                  myCB2={myCB2}
                />
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <div className="prod-p" id="jozeko">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/jozeko/main.png`}
          alt=""
          className="first-proj-img"
        ></img>

        <div className="first-project">
          <div>
            <div style={{ display: disForProj4 }}>
              <Slide left>
                <TextCard
                  prjName="Jozeko"
                  prjDesc="A Socail Media Mobile App"
                  btnText="More Information"
                  callbackFromParent={myCallback}
                  callbackFromParent2={myCallback2}
                  callbackFromParent3={myCallback3}
                  callbackFromParent4={myCallback4}
                  callbackFromParent5={myCallback5}
                  callbackFromParent6={myCallback6}
                  callbackFromParent7={myCallback7}
                  callbackFromParent8={myCallback8}
                  callbackFromParent9={myCallback9}
                  callbackFromParent10={myCallback10}
                  callbackFromParent11={myCallback11}
                  callbackFromParent12={myCallback12}
                  accentClr="#fff"
                  backAccentClr="#2fb290"
                />
              </Slide>
            </div>
            <div style={{ display: disForProjDet4 }}>
              <Fade right>
                <BigModal
                  prjName="Jozeko"
                  prjDesc="Jozeko is your social media buddy. It is a video and photo sharing social media networking service. This social media service is a simple, fun and creative way to capture, edit and share photos, videos, messages and video call with friends and family. Whether you are a reader, pet enthusiast or just looking for a laugh there is something for everyone on Jozeko."
                  btnText="Close"
                  callbackFromParent={myCallback}
                  callbackFromParent2={myCallback2}
                  callbackFromParent3={myCallback3}
                  callbackFromParent4={myCallback4}
                  callbackFromParent5={myCallback5}
                  callbackFromParent6={myCallback6}
                  callbackFromParent7={myCallback7}
                  callbackFromParent8={myCallback8}
                  callbackFromParent9={myCallback9}
                  callbackFromParent10={myCallback10}
                  callbackFromParent11={myCallback11}
                  callbackFromParent12={myCallback12}
                  mobile={true}
                  techList={["react", "javascript", "node", "mongo"]}
                  mobSS={jozeko}
                  accentClr="#fff"
                  backAccentClr="#2fb290"
                  btnClr="#2fb290"
                  myCB2={myCB2}
                />
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <div className="prod-p" style={{ height: "100vh" }} id="broadband">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/broadband/main.png`}
          alt=""
          className="first-proj-img"
        ></img>

        <div className="first-project">
          <div>
            <div style={{ display: disForProj5 }}>
              <Slide left>
                <TextCard
                  prjName="Broadband"
                  prjDesc="An E-Commerce Broadband Platform"
                  btnText="More Information"
                  callbackFromParent={myCallback}
                  callbackFromParent2={myCallback2}
                  callbackFromParent3={myCallback3}
                  callbackFromParent4={myCallback4}
                  callbackFromParent5={myCallback5}
                  callbackFromParent6={myCallback6}
                  callbackFromParent7={myCallback7}
                  callbackFromParent8={myCallback8}
                  callbackFromParent9={myCallback9}
                  callbackFromParent10={myCallback10}
                  callbackFromParent11={myCallback11}
                  callbackFromParent12={myCallback12}
                  accentClr="#fff"
                  backAccentClr="#213a4a"
                  btnClr="#fff"
                />
              </Slide>
            </div>
            <div style={{ display: disForProjDet5 }}>
              <Fade right>
                <BigModal
                  prjName="Broadband"
                  prjDesc="Listen up, internet users! Broadband is an E-Commerce Broadband Platform where our goal is pretty simple: we want to help you get a better and cheaper broadband deal."
                  btnText="Close"
                  callbackFromParent={myCallback}
                  callbackFromParent2={myCallback2}
                  callbackFromParent3={myCallback3}
                  callbackFromParent4={myCallback4}
                  callbackFromParent5={myCallback5}
                  callbackFromParent6={myCallback6}
                  callbackFromParent7={myCallback7}
                  callbackFromParent8={myCallback8}
                  callbackFromParent9={myCallback9}
                  callbackFromParent10={myCallback10}
                  callbackFromParent11={myCallback11}
                  callbackFromParent12={myCallback12}
                  web={true}
                  techList={[
                    "react",
                    "bootstrap",
                    "css",
                    "javascript",
                    "node",
                    "mongo",
                  ]}
                  webSS={broadband}
                  accentClr="#fff"
                  backAccentClr="#213a4a"
                  btnClr="#213a4a"
                  myCB2={myCB2}
                />
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <div className="prod-p" style={{ height: "100vh" }} id="aceso">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/aceso/main.png`}
          alt=""
          className="first-proj-img"
        ></img>

        <div className="first-project">
          <div>
            <div style={{ display: disForProj6 }}>
              <Slide left>
                <TextCard
                  prjName="Aceso"
                  prjDesc="The Fitness App"
                  btnText="More Information"
                  callbackFromParent={myCallback}
                  callbackFromParent2={myCallback2}
                  callbackFromParent3={myCallback3}
                  callbackFromParent4={myCallback4}
                  callbackFromParent5={myCallback5}
                  callbackFromParent6={myCallback6}
                  callbackFromParent7={myCallback7}
                  callbackFromParent8={myCallback8}
                  callbackFromParent9={myCallback9}
                  callbackFromParent10={myCallback10}
                  callbackFromParent11={myCallback11}
                  callbackFromParent12={myCallback12}
                  accentClr="#1e2430"
                  backAccentClr="#ff0c6c"
                  btnClr="#fff"
                />
              </Slide>
            </div>
            <div style={{ display: disForProjDet6 }}>
              <Fade right>
                <BigModal
                  prjName="Aceso"
                  prjDesc="Aceso is a mobile app that uses Artificial Intelligence to provide users with a virtual Personal Trainer & Physical Therapist. It will learn their habits, workout ability and adapt. Tailoring each workout/exercise based on the previous ones & the users end goal to challenge them a bit more every time. The Rehabilitation side of the app will create custom Home Exercise Plans that will help users recover from most musculoskeletal disorders, while tracking their progress."
                  btnText="Close"
                  callbackFromParent={myCallback}
                  callbackFromParent2={myCallback2}
                  callbackFromParent3={myCallback3}
                  callbackFromParent4={myCallback4}
                  callbackFromParent5={myCallback5}
                  callbackFromParent6={myCallback6}
                  callbackFromParent7={myCallback7}
                  callbackFromParent8={myCallback8}
                  callbackFromParent9={myCallback9}
                  callbackFromParent10={myCallback10}
                  callbackFromParent11={myCallback11}
                  callbackFromParent12={myCallback12}
                  mobile={true}
                  techList={["react", "javascript", "node", "mongo"]}
                  mobSS={aceso}
                  accentClr="#1e2430"
                  backAccentClr="#ff0c6c"
                  btnClr="#fff"
                  myCB2={myCB2}
                />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioProducts;

import React from "react";
import "./TechDev.css";

const TechDev = () => {
  return (
    <div>
      <svg
        className="tech-class"
        viewBox="0 0 1088 827"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="undraw_code_typing_7jnv 1" clipPath="url(#clip0)">
          <path
            id="Vector"
            d="M934.987 329.388C887.167 141.348 721.934 5.61004 527.985 0.165699C425.451 -2.71256 317.556 31.2936 239 150.294C98.5735 363.019 247.63 510.019 351.548 579.87C401.752 613.685 446.99 654.344 485.95 700.67C556.143 783.966 691.179 887.472 852 725.294C968.564 607.746 966.84 454.64 934.987 329.388Z"
            fill="#F2F2F2"
          />
          <path
            id="Vector_2"
            opacity="0.1"
            d="M438.6 416.294H478.6V376.294H438.6V416.294ZM639.4 376.294H599.4V416.294H639.4V376.294ZM519 416.294H559V376.294H519V416.294Z"
            fill="black"
          />
          <path
            id="Vector_3"
            d="M1088 752.294C1088 768.862 844.443 826.294 544 826.294C243.557 826.294 0 768.862 0 752.294C0 735.725 243.557 766.294 544 766.294C844.443 766.294 1088 735.725 1088 752.294Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_4"
            opacity="0.1"
            d="M1088 752.294C1088 768.862 844.443 826.294 544 826.294C243.557 826.294 0 768.862 0 752.294C0 735.725 243.557 766.294 544 766.294C844.443 766.294 1088 735.725 1088 752.294Z"
            fill="black"
          />
          <path
            id="Vector_5"
            d="M544 782.294C844.443 782.294 1088 768.862 1088 752.294C1088 735.725 844.443 722.294 544 722.294C243.557 722.294 0 735.725 0 752.294C0 768.862 243.557 782.294 544 782.294Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_6"
            d="M632.696 607.953C632.696 671.969 672.856 723.77 722.487 723.77Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_7"
            d="M722.487 723.77C722.487 659.035 767.303 606.652 822.688 606.652Z"
            fill="#5B1B76"
          />
          <path
            id="Vector_8"
            d="M665.229 613.756C665.229 674.565 690.838 723.77 722.487 723.77Z"
            fill="#5B1B76"
          />
          <path
            id="Vector_9"
            d="M722.487 723.77C722.487 641.053 774.288 574.119 838.304 574.119Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_10"
            d="M703.597 724.587C703.597 724.587 716.331 724.195 720.169 721.462C724.006 718.729 739.756 715.466 740.708 719.849C741.661 724.232 759.845 741.646 745.469 741.762C731.092 741.878 712.064 739.523 708.234 737.19C704.404 734.857 703.597 724.587 703.597 724.587Z"
            fill="#A8A8A8"
          />
          <path
            id="Vector_11"
            opacity="0.2"
            d="M745.725 740.237C731.349 740.353 712.321 737.997 708.491 735.664C705.574 733.887 704.411 727.512 704.022 724.57C703.753 724.582 703.597 724.587 703.597 724.587C703.597 724.587 704.404 734.857 708.234 737.19C712.064 739.523 731.092 741.878 745.469 741.762C749.619 741.729 751.052 740.253 750.973 738.066C750.397 739.387 748.814 740.212 745.725 740.237Z"
            fill="black"
          />
          <path
            id="Vector_12"
            d="M914.054 265.704C913.722 269.841 913.789 274 914.253 278.124C915.437 287.074 919.556 296.403 915.665 304.757C914.252 307.789 911.859 310.432 910.555 313.5C907.796 319.997 910.298 327.202 909.923 334.116C909.438 343.048 903.757 351.654 895.116 356.549C909.88 357.007 923.92 349.708 933.209 339.761C942.499 329.814 947.672 317.477 951.55 305.133C953.544 299.336 954.821 293.318 955.355 287.211C955.665 283.703 955.253 280.169 954.147 276.825C952.782 273.559 950.985 270.49 948.805 267.702C946.479 264.431 944.089 261.097 940.657 258.663C936.43 255.666 928.279 252.657 922.672 253.093C915.793 253.628 914.343 260.93 914.054 265.704Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_13"
            d="M936.792 290.146C936.792 290.146 945.361 320.527 938.35 329.096C931.339 337.665 980.415 325.201 980.415 325.201C980.415 325.201 956.267 301.052 967.952 285.472L936.792 290.146Z"
            fill="#FFB9B9"
          />
          <path
            id="Vector_14"
            opacity="0.1"
            d="M936.792 290.146C936.792 290.146 945.361 320.527 938.35 329.096C931.339 337.665 980.415 325.201 980.415 325.201C980.415 325.201 956.267 301.052 967.952 285.472L936.792 290.146Z"
            fill="black"
          />
          <path
            id="Vector_15"
            d="M908.748 695.221V717.812L920.433 731.833L930.56 727.159L927.444 694.442L908.748 695.221Z"
            fill="#FFB9B9"
          />
          <path
            id="Vector_16"
            d="M913.422 716.254C913.422 716.254 910.306 705.348 904.853 713.138C899.401 720.927 893.948 738.065 890.832 738.844C887.716 739.623 865.904 763.772 887.716 763.772C909.527 763.772 916.538 753.645 916.538 753.645C916.538 753.645 938.35 745.076 938.35 743.518C938.35 741.96 935.234 713.138 933.676 713.138C932.118 713.138 931.339 718.59 927.444 718.59C923.549 718.59 913.422 716.254 913.422 716.254Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_17"
            d="M960.941 699.116L960.162 720.149L978.857 727.938V697.558L960.941 699.116Z"
            fill="#FFB9B9"
          />
          <path
            id="Vector_18"
            d="M981.194 720.148C981.194 720.148 971.068 709.243 965.615 711.58C962.917 712.665 960.312 713.968 957.825 715.475C957.825 715.475 954.709 725.601 953.151 734.17C951.593 742.739 950.814 746.634 949.256 749.75C947.698 752.866 946.14 764.551 961.72 766.109C977.299 767.667 981.973 759.098 981.973 759.098L985.868 738.844C985.868 738.844 984.31 720.148 981.194 720.148Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_19"
            d="M918.875 474.767C918.875 474.767 896.285 525.401 899.4 571.362C899.4 571.362 891.611 589.278 896.285 617.322C900.959 645.366 907.19 701.453 907.19 701.453C907.19 701.453 928.223 703.79 929.781 699.895C929.781 699.895 931.339 660.945 932.897 659.387C934.455 657.829 932.118 621.996 929.781 614.985C927.444 607.974 951.593 538.644 951.593 538.644L945.361 452.176L918.875 474.767Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_20"
            opacity="0.1"
            d="M918.875 474.767C918.875 474.767 896.285 525.401 899.4 571.362C899.4 571.362 891.611 589.278 896.285 617.322C900.959 645.366 907.19 701.453 907.19 701.453C907.19 701.453 928.223 703.79 929.781 699.895C929.781 699.895 931.339 660.945 932.897 659.387C934.455 657.829 932.118 621.996 929.781 614.985C927.444 607.974 951.593 538.644 951.593 538.644L945.361 452.176L918.875 474.767Z"
            fill="black"
          />
          <path
            id="Vector_21"
            d="M912.643 471.651L927.444 503.59C927.444 503.59 934.455 576.035 938.35 585.383C938.35 585.383 939.908 611.869 946.919 620.438C953.93 629.007 959.383 704.569 959.383 704.569C959.383 704.569 978.857 710.022 980.415 704.569C981.973 699.116 986.647 661.724 986.647 661.724C986.647 661.724 988.205 629.786 979.636 608.753C971.068 587.72 989.763 512.158 989.763 512.158C989.763 512.158 1009.24 475.546 981.194 445.944L912.643 471.651Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_22"
            d="M953.93 299.494C971.569 299.494 985.868 285.195 985.868 267.556C985.868 249.917 971.569 235.617 953.93 235.617C936.291 235.617 921.991 249.917 921.991 267.556C921.991 285.195 936.291 299.494 953.93 299.494Z"
            fill="#FFB9B9"
          />
          <path
            id="Vector_23"
            d="M983.531 315.853C983.531 315.853 950.035 315.853 945.361 319.748C944.206 320.848 942.672 321.461 941.077 321.461C939.481 321.461 937.948 320.848 936.792 319.748C936.792 319.748 925.107 320.527 921.991 329.875C918.875 339.223 909.527 351.687 909.527 351.687C909.527 351.687 895.506 361.813 903.295 388.299C911.085 414.785 904.854 457.629 904.854 457.629C904.854 457.629 898.622 466.977 904.854 470.872C911.085 474.767 913.422 476.325 912.643 479.441C911.864 482.557 983.531 453.734 983.531 448.281C983.531 442.828 981.973 438.154 983.531 435.817C985.089 433.48 986.647 429.585 985.089 425.69C983.531 421.796 986.647 421.017 985.868 417.122C985.089 413.227 989.763 384.404 992.879 371.94C995.995 359.476 995.216 316.632 983.531 315.853Z"
            fill="#D0CDE1"
          />
          <g id="girl-code">
            <path
              id="Vector_24"
              opacity="0.1"
              d="M719.6 507.394L818 409.294L719.6 311.194L749.6 281.294L878 409.294L749.6 537.294L719.6 507.394Z"
              fill="black"
            />
            <path
              id="Vector_25"
              d="M967.173 308.842C967.173 308.842 930.56 374.277 929.781 383.625C929.002 392.973 929.002 393.752 925.886 392.973C922.77 392.194 879.926 387.52 876.031 389.078C872.136 390.636 864.346 379.73 864.346 379.73C864.346 379.73 849.545 394.531 852.661 397.647C855.777 400.763 865.125 402.321 866.683 406.216C868.241 410.111 870.578 414.006 877.589 413.227C884.6 412.448 932.118 425.691 938.35 423.354C944.582 421.017 946.14 417.122 949.256 417.122C952.372 417.122 958.604 414.006 958.604 413.227C958.604 412.448 980.415 371.161 980.415 371.161C980.415 371.161 1006.12 315.074 967.173 308.842Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_26"
              d="M719.6 488.394L818 390.294L719.6 292.194L749.6 262.294L878 390.294L749.6 518.294L719.6 488.394Z"
              fill="#5B1B76"
            />
            <path
              id="Vector_27"
              d="M856.28 393.695C856.28 393.695 825.492 370.319 823.155 378.888C820.818 387.457 845.745 406.932 845.745 406.932L856.28 393.695Z"
              fill="#FFB9B9"
            />
            <path
              id="Vector_28"
              d="M952.942 316C952.942 316 916.33 381.435 915.551 390.783C914.772 400.131 914.772 400.91 911.656 400.131C908.54 399.352 865.696 394.678 861.801 396.236C857.906 397.794 850.116 386.888 850.116 386.888C850.116 386.888 835.315 401.689 838.431 404.805C841.547 407.921 850.895 409.479 852.453 413.374C854.011 417.269 856.348 421.164 863.359 420.385C870.37 419.606 917.888 432.848 924.12 430.511C930.352 428.174 931.91 424.28 935.026 424.28C938.142 424.28 944.374 421.164 944.374 420.385C944.374 419.606 966.185 378.319 966.185 378.319C966.185 378.319 991.892 322.232 952.942 316Z"
              fill="#D0CDE1"
            />
          </g>
          <path
            id="Vector_29"
            d="M938.114 287.961C937.672 284.798 936.052 281.928 935.221 278.844C934.57 276.376 934.426 273.802 934.796 271.276C935.166 268.751 936.044 266.326 937.375 264.149C941.956 256.749 950.951 253.472 957.223 247.44C957.353 250.513 959.485 253.139 961.842 255.115C964.199 257.091 966.902 258.694 968.897 261.034C970.814 263.503 972.211 266.334 973.006 269.358C974.235 272.861 974.786 276.565 974.629 280.275C974.539 282.128 974.074 283.944 973.26 285.611C972.447 287.279 971.303 288.764 969.898 289.977C967.562 291.823 964.297 292.878 963.169 295.633C962.725 297.01 962.597 298.47 962.793 299.903C963.328 308.473 963.828 317.197 961.81 325.543C959.792 333.89 954.847 341.954 947.098 345.654C953.466 346.105 959.969 346.546 966.147 344.938C972.325 343.331 978.234 339.283 980.364 333.265C981.415 330.296 981.514 326.994 983.007 324.221C986.786 317.203 997.222 316.883 1002.35 310.778C1004.4 308.336 1005.39 305.188 1006.13 302.084C1010.45 283.819 1007.41 264.425 1000.82 246.849C998.554 240.791 995.727 234.691 990.855 230.434C986.71 226.81 981.417 224.814 976.182 223.109C968.542 220.621 960.672 218.583 952.642 218.32C949.154 218.115 945.656 218.523 942.309 219.526C939.004 220.72 935.929 222.472 933.217 224.706C920.637 234.494 912.073 250.137 913.209 266.035C913.897 275.655 917.973 284.759 923.209 292.858C925.04 295.691 929.367 301.731 933.389 299.95C937.037 298.334 938.593 291.377 938.114 287.961Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_30"
            d="M438.6 397.294H478.6V357.294H438.6V397.294ZM639.4 357.294H599.4V397.294H639.4V357.294ZM519 397.294H559V357.294H519V397.294Z"
            fill="#5B1B76"
          />
          <path
            id="Vector_31"
            d="M206.206 238.091C224.794 238.091 239.863 226.337 239.863 211.839C239.863 197.34 224.794 185.586 206.206 185.586C187.618 185.586 172.549 197.34 172.549 211.839C172.549 226.337 187.618 238.091 206.206 238.091Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_32"
            d="M149.552 706.138C149.552 706.138 135.435 741.738 142.801 746.034C150.166 750.331 227.502 747.262 227.502 747.262C227.502 747.262 240.392 739.282 225.661 733.758C225.661 733.758 213.385 733.145 205.406 728.234C197.427 723.324 188.22 714.117 188.22 714.117C188.22 714.117 187.607 700.614 182.696 703.683C181.227 704.651 179.977 705.916 179.026 707.395C178.074 708.875 177.443 710.538 177.172 712.276L149.552 706.138Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_33"
            opacity="0.1"
            d="M149.552 706.138C149.552 706.138 135.435 741.738 142.801 746.034C150.166 750.331 227.502 747.262 227.502 747.262C227.502 747.262 240.392 739.282 225.661 733.758C225.661 733.758 213.385 733.145 205.406 728.234C197.427 723.324 188.22 714.117 188.22 714.117C188.22 714.117 187.607 700.614 182.696 703.683C181.227 704.651 179.977 705.916 179.026 707.395C178.074 708.875 177.443 710.538 177.172 712.276L149.552 706.138Z"
            fill="black"
          />
          <path
            id="Vector_34"
            d="M127.785 717.029C127.785 717.029 112.303 756.071 120.381 760.783C128.458 765.495 213.274 762.129 213.274 762.129C213.274 762.129 227.41 753.378 211.254 747.32C211.254 747.32 197.792 746.647 189.041 741.262C180.29 735.877 170.193 725.78 170.193 725.78C170.193 725.78 169.52 710.971 164.135 714.336C162.524 715.398 161.152 716.785 160.109 718.407C159.066 720.03 158.373 721.854 158.077 723.76L127.785 717.029Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_35"
            d="M207.216 469.314L197.792 554.129C197.792 554.129 194.426 594.518 184.329 603.268C184.329 603.268 184.329 616.058 180.963 620.097L176.251 673.275C176.251 673.275 182.983 692.796 182.309 696.162C181.636 699.527 185.002 710.297 184.329 711.644C183.656 712.99 152.018 711.644 152.018 711.644L158.75 603.268L179.617 452.486L207.216 469.314Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_36"
            opacity="0.1"
            d="M207.216 469.314L197.792 554.129C197.792 554.129 194.426 594.518 184.329 603.268C184.329 603.268 184.329 616.058 180.963 620.097L176.251 673.275C176.251 673.275 182.983 692.796 182.309 696.162C181.636 699.527 185.002 710.297 184.329 711.644C183.656 712.99 152.018 711.644 152.018 711.644L158.75 603.268L179.617 452.486L207.216 469.314Z"
            fill="black"
          />
          <path
            id="Vector_37"
            d="M190.387 466.621C190.387 466.621 182.983 535.955 179.617 550.764C177.501 560.516 176.15 570.419 175.578 580.382C175.578 580.382 174.905 601.922 168.847 607.98C167.074 609.735 165.681 611.836 164.755 614.152C163.829 616.468 163.388 618.95 163.462 621.443C163.462 621.443 157.403 689.43 160.769 697.508C164.135 705.585 167.5 712.317 164.135 717.702C160.769 723.087 123.746 723.087 123.746 719.048C123.746 715.009 123.746 711.644 121.727 709.624C119.708 707.605 121.727 694.815 121.727 694.815C121.727 694.815 113.649 634.906 121.054 617.404C128.458 599.903 131.824 552.11 131.824 552.11C131.824 552.11 126.439 497.586 119.708 488.835C112.976 480.084 121.054 449.12 121.054 449.12L190.387 466.621Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_38"
            d="M171.436 251.312C172.218 253.095 174.091 254.087 175.796 255.027C185.679 260.471 193.952 268.365 202.103 276.168C200.069 269.276 200.502 261.891 203.328 255.284C204.301 253.03 205.576 250.608 204.881 248.254C204.369 246.523 202.895 245.275 201.467 244.17C198.142 241.598 194.666 239.227 191.058 237.068C188.537 235.56 183.356 231.546 180.463 231.983C180.311 235.891 180.232 239.385 178.521 243.037C178.115 243.903 171.735 251.993 171.436 251.312Z"
            fill="#A0616A"
          />
          <path
            id="Vector_39"
            d="M205.869 259.968C221.112 259.968 233.468 247.612 233.468 232.369C233.468 217.127 221.112 204.771 205.869 204.771C190.627 204.771 178.271 217.127 178.271 232.369C178.271 247.612 190.627 259.968 205.869 259.968Z"
            fill="#A0616A"
          />
          <path
            id="Vector_40"
            d="M207.216 297.664C207.216 297.664 211.928 274.104 198.465 266.026C185.002 257.949 169.52 248.525 169.52 248.525C169.52 248.525 164.808 247.852 162.788 257.949C162.788 257.949 124.42 265.353 120.381 301.703C116.342 338.052 123.073 391.23 123.073 391.23C123.073 391.23 117.015 437.677 109.611 462.583C109.611 462.583 103.552 467.968 130.478 459.89C157.403 451.812 204.523 482.777 204.523 482.777C204.523 482.777 209.235 478.065 209.235 474.699C209.235 471.333 214.62 451.812 214.62 451.812C214.62 451.812 219.332 369.016 221.352 359.593C223.371 350.169 213.274 299.01 207.216 297.664Z"
            fill="#575A89"
          />
          <path
            id="Vector_41"
            d="M199.365 235.213C201.023 234.294 202.882 233.798 204.778 233.771C206.675 233.743 208.528 233.191 210.132 232.176C212.739 230.174 212.93 226.306 214.837 223.63C217.599 219.754 223.107 219.288 227.861 219.482C230.769 219.6 233.901 219.774 236.35 218.201C238.864 216.586 240.003 213.544 240.851 210.679C241.853 207.291 242.502 203.148 240.018 200.635C238.293 198.89 235.57 198.545 233.654 197.013C231.667 195.424 230.85 192.838 229.77 190.534C228.541 187.728 226.597 185.293 224.132 183.473C220.518 180.962 215.901 180.399 211.5 180.446C207.098 180.493 202.679 181.071 198.31 180.531C194.731 180.089 191.163 178.898 187.595 179.426C183.05 180.098 179.289 183.528 177.049 187.54C174.809 191.553 173.848 196.133 172.916 200.632L170.381 212.87C169.595 216.666 168.806 220.563 169.413 224.391C170.671 232.328 177.69 238.636 178.173 246.657C178.634 244.443 179.638 242.377 181.094 240.645C182.55 238.914 184.413 237.571 186.516 236.737C187.043 236.479 187.642 236.406 188.216 236.531C188.913 236.754 189.321 237.45 189.761 238.035C190.914 239.568 194.536 241.894 196.59 240.807C198.427 239.834 197.055 236.779 199.365 235.213Z"
            fill="#2F2E41"
          />
          <g id="boy-code">
            <path
              id="Vector_42"
              opacity="0.1"
              d="M384.4 506.394L285.9 408.294L384.3 310.194L354.3 280.294L226 408.294L354.4 536.294L384.4 506.394Z"
              fill="black"
            />
            <path
              id="Vector_43"
              d="M211.928 392.576C211.928 392.576 242.219 394.596 247.604 390.557C252.989 386.518 275.876 395.942 277.895 401.327C279.914 406.712 245.584 407.385 240.199 407.385C234.814 407.385 209.235 415.463 209.235 415.463L211.928 392.576Z"
              fill="#A0616A"
            />
            <path
              id="Vector_44"
              d="M384.4 487.394L285.9 389.294L384.3 291.194L354.3 261.294L226 389.294L354.4 517.294L384.4 487.394Z"
              fill="#5B1B76"
            />
            <path
              id="Vector_45"
              d="M129.805 360.939C129.805 360.939 125.093 390.557 144.614 387.864C144.614 387.864 154.711 386.518 156.057 387.864C157.403 389.211 207.216 380.46 213.947 373.055C213.947 373.055 222.025 370.363 226.063 374.402C230.102 378.44 270.491 382.479 258.374 370.363C246.258 358.246 227.41 355.554 227.41 355.554L201.83 356.9C201.83 356.9 188.368 360.939 180.29 356.9C172.212 352.861 149.326 354.208 149.326 354.208L129.805 360.939Z"
              fill="#A0616A"
            />
          </g>
          <path
            id="Vector_46"
            d="M157.403 267.373C157.403 267.373 127.112 270.738 127.112 314.492V370.363C127.112 370.363 143.941 362.958 147.306 363.631C150.672 364.304 164.135 354.881 164.135 354.881C164.135 354.881 179.617 318.531 178.271 308.434C176.924 298.337 177.598 268.719 157.403 267.373Z"
            fill="#575A89"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="1088" height="826.294" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default TechDev;

import React from "react";
import "./Des.css";

const Des = () => {
  return (
    <div>
      <svg
        className="des-class"
        viewBox="0 0 1014 845"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="undraw_new_ideas_jdea 1" clipPath="url(#clip0)">
          <path
            id="Vector"
            d="M956.999 270.31C956.999 305.48 948.669 334.43 934.219 359.23C929.45 367.399 924.036 375.173 918.029 382.48C864.909 447.45 766.149 483.01 676.889 540.61C413.409 710.63 384.869 438.19 396.769 270.31C401.569 202.57 427.949 140.52 469.859 93.01C478.661 83.0179 488.168 73.6705 498.309 65.04C545.889 24.52 607.359 0 676.889 0C796.179 0 898.049 71.95 938.439 173.34C950.718 204.194 957.017 237.102 956.999 270.31V270.31Z"
            fill="#5B1B76"
          />
          <path
            id="Vector_2"
            d="M736.569 60.92L469.859 93.01C478.661 83.0178 488.168 73.6705 498.309 65.04L736.569 60.92Z"
            fill="#D0CDE1"
          />
          <path
            id="Vector_3"
            d="M934.219 359.23C929.45 367.399 924.036 375.173 918.029 382.48L689.569 355L934.219 359.23Z"
            fill="#D0CDE1"
          />
          <path
            id="Vector_4"
            d="M947.759 201.22L560.569 151L938.439 173.34C942.068 182.45 945.179 191.759 947.759 201.22V201.22Z"
            fill="#D0CDE1"
          />
          <path
            id="Vector_5"
            d="M387.612 844.995L272.402 837.314L267.281 775.868L241.679 829.633L129.029 814.272C131.589 716.983 172.553 622.254 175.113 609.453C177.673 596.652 183.792 543.758 183.792 543.758C194.98 520.024 253.609 517.797 299.566 519.947C318.614 520.818 335.511 522.456 345.931 523.634C352.818 524.402 356.889 524.966 356.889 524.966C375.246 543.886 387.074 569.667 394.345 598.47C420.562 702.236 387.612 844.995 387.612 844.995Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_6"
            d="M346.648 530.086L304.174 534.797L185.354 548.008C185.354 548.008 184.79 546.497 183.792 543.758C175.164 520.229 133.611 405.736 129.029 371.352C123.908 332.948 177.673 286.864 177.673 286.864C177.673 286.864 228.878 276.623 244.239 276.623C259.6 276.623 321.046 299.665 321.046 299.665L343.96 505.789L345.931 523.634L346.648 530.086Z"
            fill="#D0CDE1"
          />
          <path
            id="Vector_7"
            opacity="0.15"
            d="M394.345 598.47L308.245 548.008C308.245 548.008 306.734 543.092 304.174 534.797C302.894 530.649 301.332 525.631 299.566 519.947C289.632 488.047 272.965 435.408 259.6 396.954C239.119 338.069 338.968 501.923 338.968 501.923L343.96 505.789L345.931 523.634C352.818 524.402 356.889 524.966 356.889 524.966C375.246 543.886 387.074 569.667 394.345 598.47Z"
            fill="black"
          />
          <g id="hand">
            <path
              id="Vector_8"
              d="M477.681 614C477.681 614 539.126 616.56 539.126 631.922C539.126 647.283 470 631.922 470 631.922L477.681 614Z"
              fill="#A0616A"
            />
            <path
              id="Vector_9"
              d="M280.062 308.575C280.062 308.575 254.46 341.858 274.942 400.743C295.424 459.628 323.586 551.797 323.586 551.797L472.08 638.845L492.562 613.242L354.309 505.713C354.309 505.713 349.189 311.135 323.586 303.454C297.984 295.773 280.062 308.575 280.062 308.575Z"
              fill="#D0CDE1"
            />
          </g>
          <path
            id="Vector_10"
            d="M153.351 528.806C153.351 528.806 189.194 603.053 204.555 595.372C219.917 587.691 166.152 510.884 166.152 510.884L153.351 528.806Z"
            fill="#A0616A"
          />
          <path
            id="Vector_11"
            d="M149.51 325.267L13.8181 437.918C13.8181 437.918 -37.3866 473.761 57.3421 499.363C152.071 524.966 159.751 540.327 159.751 540.327C159.751 540.327 169.992 507.044 182.793 512.164L72.7034 458.4L162.312 404.635L149.51 325.267Z"
            fill="#D0CDE1"
          />
          <g id="face">
            <path
              id="Vector_12"
              d="M259.6 251.021C294.95 251.021 323.606 222.364 323.606 187.015C323.606 151.665 294.95 123.009 259.6 123.009C224.251 123.009 195.595 151.665 195.595 187.015C195.595 222.364 224.251 251.021 259.6 251.021Z"
              fill="#A0616A"
            />
            <path
              id="Vector_13"
              d="M216.076 202.376C216.076 202.376 198.155 281.743 169.992 304.786C141.83 327.828 280.082 304.786 280.082 304.786C280.082 304.786 249.359 253.581 272.402 230.539C295.444 207.497 216.076 202.376 216.076 202.376Z"
              fill="#A0616A"
            />
            <path
              id="Vector_14"
              d="M94.9743 239.549C102.096 236.022 117.822 228.904 131.218 220.329C130.278 192.431 133.714 167.106 143.77 153.682C149.414 140.865 161.268 128.034 170.551 117.929C193.349 93.1134 243.761 79.2617 252.702 91.6603C320.327 92.9954 327.447 146.003 327.447 146.003C327.095 168.175 304.051 168.877 283.865 168.069C285.672 183.205 280.855 198.933 266.905 205.908C240.229 219.246 232.226 236.585 245.564 248.59C258.903 260.594 282.911 292.605 250.9 300.608C218.888 308.611 214.887 324.617 224.224 331.285C233.56 337.954 202.883 319.281 202.883 319.281C202.883 319.281 197.548 351.293 169.538 343.29C167.028 342.572 164.519 340.735 162.052 337.986C154.488 346.298 147.739 351.213 142.653 351.24C113.522 351.394 117.016 319.13 117.016 319.13C117.016 319.13 82.4395 328.841 93.2285 324.936C104.018 321.031 104.48 304.54 75.8116 288.202C47.1435 271.865 78.8936 247.512 94.9743 239.549Z"
              fill="#2F2E41"
            />
          </g>
          <g id="bulb">
            <path
              id="Vector_15"
              d="M452.384 323.181L451.919 323.12L453.052 327.426C454.123 333.434 455.678 339.346 457.702 345.103L490.929 471.411C491.989 475.442 494.203 479.076 497.3 481.867C500.396 484.658 504.24 486.484 508.359 487.121L576.119 497.603C580.501 498.281 584.984 497.582 588.951 495.602C592.918 493.623 596.173 490.46 598.265 486.552L662.659 366.275C664.671 362.943 666.51 359.509 668.167 355.987L670.354 351.903L670.013 351.858C676.839 335.711 679.844 318.205 678.793 300.705C677.742 283.205 672.663 266.184 663.954 250.97C655.244 235.755 643.138 222.757 628.58 212.99C614.022 203.222 597.405 196.949 580.024 194.658C562.642 192.368 544.967 194.123 528.376 199.786C511.784 205.448 496.725 214.867 484.371 227.306C472.017 239.745 462.703 254.869 457.155 271.499C451.606 288.129 449.974 305.816 452.384 323.181H452.384Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_16"
              d="M580.277 505.856C579.084 505.856 577.892 505.764 576.713 505.581L505.885 494.623C501.387 493.928 497.189 491.934 493.808 488.887C490.426 485.839 488.009 481.87 486.851 477.468L452.121 345.442C449.998 339.398 448.367 333.192 447.243 326.886L446.364 321.11C444.001 302.658 445.96 283.909 452.085 266.344C458.21 248.778 468.334 232.877 481.658 219.895C494.982 206.913 511.142 197.206 528.86 191.54C546.579 185.874 565.373 184.404 583.757 187.247L583.45 189.223C565.266 186.421 546.679 187.907 529.171 193.564C511.664 199.22 495.72 208.889 482.613 221.801C469.505 234.712 459.596 250.508 453.677 267.928C447.758 285.349 445.991 303.912 448.519 322.136L449.194 326.456C450.31 332.709 451.928 338.863 454.031 344.856L488.786 476.959C489.844 480.985 492.055 484.614 495.147 487.401C498.239 490.188 502.077 492.011 506.191 492.647L577.019 503.605C581.393 504.277 585.869 503.577 589.829 501.6C593.79 499.624 597.04 496.469 599.133 492.569L600.897 493.512C598.895 497.24 595.921 500.357 592.291 502.53C588.66 504.703 584.509 505.853 580.277 505.856V505.856Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_17"
              d="M598.244 351.89L555.148 469.561L557.577 470.45L600.673 352.78L598.244 351.89Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_18"
              d="M527.878 341.002L525.293 341.116L530.794 466.309L533.378 466.196L527.878 341.002Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_19"
              d="M574.29 565.711L483.533 551.67C482.858 551.562 482.253 551.191 481.851 550.638C481.449 550.085 481.282 549.396 481.386 548.721C481.491 548.045 481.858 547.438 482.409 547.033C482.959 546.628 483.648 546.457 484.324 546.557L575.081 560.598C575.418 560.648 575.742 560.764 576.034 560.94C576.327 561.116 576.581 561.348 576.783 561.622C576.986 561.897 577.131 562.209 577.213 562.54C577.294 562.871 577.309 563.215 577.257 563.552C577.205 563.889 577.087 564.212 576.909 564.504C576.731 564.795 576.498 565.048 576.222 565.248C575.947 565.449 575.634 565.593 575.302 565.673C574.971 565.752 574.627 565.765 574.29 565.711V565.711Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_20"
              d="M576.617 546.438L485.86 532.397C485.522 532.347 485.198 532.231 484.906 532.055C484.614 531.879 484.36 531.647 484.157 531.373C483.955 531.098 483.809 530.786 483.728 530.455C483.647 530.124 483.632 529.78 483.684 529.443C483.736 529.106 483.854 528.783 484.032 528.492C484.209 528.2 484.443 527.947 484.718 527.747C484.994 527.546 485.307 527.402 485.638 527.323C485.97 527.243 486.314 527.23 486.651 527.284L577.408 541.325C577.745 541.375 578.069 541.491 578.362 541.667C578.654 541.843 578.908 542.075 579.11 542.349C579.313 542.624 579.459 542.936 579.54 543.267C579.621 543.598 579.636 543.942 579.584 544.279C579.532 544.616 579.414 544.939 579.236 545.231C579.059 545.522 578.825 545.775 578.55 545.975C578.274 546.176 577.961 546.32 577.629 546.4C577.298 546.479 576.954 546.492 576.617 546.438V546.438Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_21"
              d="M578.944 527.165L488.187 513.124C487.512 513.016 486.907 512.645 486.505 512.092C486.103 511.54 485.936 510.85 486.041 510.175C486.145 509.499 486.513 508.893 487.063 508.487C487.613 508.082 488.302 507.911 488.978 508.011L579.735 522.052C580.41 522.16 581.015 522.531 581.417 523.084C581.819 523.637 581.986 524.326 581.881 525.001C581.777 525.677 581.409 526.284 580.859 526.689C580.309 527.094 579.62 527.265 578.944 527.165H578.944Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_22"
              d="M526.09 576.926C544.092 579.711 560.238 571.935 562.153 559.559L496.961 549.474C495.046 561.85 508.088 574.141 526.09 576.926Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_23"
              d="M558.714 327.099C554.841 326.501 551.637 323.44 549.881 321.388C549.305 320.716 548.744 320.018 548.182 319.319C546.137 316.773 544.205 314.368 541.516 313.114C535.579 310.344 528.584 314.213 522.527 318.364L521.064 316.231C527.723 311.665 535.488 307.448 542.61 310.769C545.845 312.278 548.058 315.034 550.199 317.698C550.744 318.377 551.288 319.054 551.846 319.706C553.672 321.839 557.315 325.295 561.217 324.498C562.862 324.018 564.392 323.209 565.713 322.118C566.719 321.367 567.783 320.696 568.894 320.111C571.148 319.03 573.67 318.64 576.145 318.99C578.62 319.34 580.935 320.413 582.801 322.076C583.466 322.706 584.096 323.373 584.688 324.072C585.852 325.398 586.951 326.65 588.381 327.105C590.495 327.781 592.73 326.573 595.098 325.296C597.54 323.978 600.064 322.615 602.927 322.952C603.02 322.963 603.114 322.975 603.207 322.99C608.302 323.778 610.651 329.413 611.981 333.619L609.513 334.398C607.707 328.681 605.508 325.838 602.625 325.521C600.573 325.28 598.511 326.394 596.327 327.572C593.589 329.051 590.755 330.579 587.595 329.57C585.481 328.898 584.089 327.312 582.743 325.778C582.213 325.151 581.65 324.552 581.055 323.985C579.569 322.684 577.736 321.844 575.781 321.567C573.825 321.291 571.831 321.59 570.043 322.429C569.052 322.955 568.104 323.557 567.206 324.23C565.601 325.539 563.737 326.494 561.737 327.032C560.741 327.235 559.717 327.258 558.714 327.099Z"
              fill="#D0CDE1"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="1013.03" height="844.995" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Des;

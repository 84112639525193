import React, { useState } from "react";
import { Zoom, Fade } from "react-reveal";
import Card from "./Card";
import classNames from "classnames";

const PortfolioTech = () => {
  const [disForFrontEnd, setDisForFrontEnd] = useState("flex");
  const [disForBackEnd, setDisForBackEnd] = useState("none");
  const [disForDB, setDisForDB] = useState("none");
  const [disForGD, setDisForGD] = useState("none");
  const [animateFE, setAnimateFE] = useState(false);
  const [animateBE, setAnimateBE] = useState(false);
  const [animateDB, setAnimateDB] = useState(false);
  const [animateGD, setAnimateGD] = useState(false);

  var intemLogo = process.env.PUBLIC_URL + "/assets/images/Logo-color.png";
  var sbLogo = process.env.PUBLIC_URL + "/assets/images/logo.png";
  //   const handleClick = () => setAnimate(!animate);

  return (
    <>
      <div className="tech-main">
        <div className="tech-text">
          <h1 className="tech-h">
            <Zoom left cascade>
              T E C H N O L O G I E S
            </Zoom>
          </h1>
          <div className="hr-tech-tag"></div>
          <div className="tech-p">
            <div className="frontend">
              <h2
                // className="fr-h"
                style={{ fontWeight: "bold" }}
                className={classNames("fr-h", animateFE && "fr-h-scale")}
                id="fr-h"
                onClick={() => {
                  setDisForBackEnd("none");
                  setDisForFrontEnd("flex");
                  setDisForDB("none");
                  setDisForGD("none");
                  setAnimateFE(true);
                  setAnimateBE(false);
                  setAnimateDB(false);
                  setAnimateGD(false);
                }}
              >
                F R O N T E N D
              </h2>
              <div className="front-tech" style={{ display: disForFrontEnd }}>
                <Card imgName="react-icon.svg" />
                <Card imgName="vue-icon.svg" />
                <Card imgName="angular-icon.svg" />
                <Card imgName="html-icon.svg" />
                <Card imgName="bootstrap-icon.svg" />
                <Card imgName="css-icon.svg" />
                {/* <Card imgName="javascript-icon.svg" /> */}
              </div>
            </div>
            <br style={{ display: disForFrontEnd }} />
            <br style={{ display: disForFrontEnd }} />
            <div className="backend">
              <h2
                style={{ fontWeight: "bold" }}
                // className="be-h"
                className={classNames("be-h", animateBE && "be-h-scale")}
                onClick={() => {
                  setDisForBackEnd("flex");
                  setDisForFrontEnd("none");
                  setDisForDB("none");
                  setDisForGD("none");
                  setAnimateFE(false);
                  setAnimateBE(true);
                  setAnimateDB(false);
                  setAnimateGD(false);
                }}
              >
                B A C K E N D
              </h2>
              <div className="back-tech" style={{ display: disForBackEnd }}>
                <Card imgName="javascript-icon.svg" />
                <Card imgName="django-icon.svg" />
                <Card imgName="node-icon.svg" />
                <Card imgName="java-icon.svg" />
                <Card imgName="python-icon.svg" />
                {/* <Card imgName="bootstrap-icon.svg" /> */}
                {/* <Card imgName="css-icon.svg" /> */}
              </div>
            </div>
            <br style={{ display: disForBackEnd }} />
            <br style={{ display: disForBackEnd }} />
            <div className="db">
              <h2
                style={{ fontWeight: "bold" }}
                className={classNames("db-h", animateDB && "db-h-scale")}
                // className="db-h"
                onClick={() => {
                  setDisForBackEnd("none");
                  setDisForFrontEnd("none");
                  setDisForGD("none");
                  setDisForDB("flex");
                  setAnimateFE(false);
                  setAnimateBE(false);
                  setAnimateDB(true);
                  setAnimateGD(false);
                }}
              >
                D A T A B A S E S
              </h2>

              <div className="db-tech" style={{ display: disForDB }}>
                <Card imgName="mongo-icon.svg" />
                <Card imgName="sql-icon.svg" />
                <Card imgName="postgre-icon.svg" />
                {/* <Card imgName="bootstrap-icon.svg" /> */}
                {/* <Card imgName="css-icon.svg" /> */}
              </div>
            </div>
            <br style={{ display: disForDB }} />
            <br style={{ display: disForDB }} />
            <div className="gd">
              <h2
                style={{ fontWeight: "bold" }}
                className={classNames("gd-h", animateGD && "gd-h-scale")}
                // className="db-h"
                onClick={() => {
                  setDisForBackEnd("none");
                  setDisForFrontEnd("none");
                  setDisForDB("none");
                  setDisForGD("flex");
                  setAnimateFE(false);
                  setAnimateBE(false);
                  setAnimateDB(false);
                  setAnimateGD(true);
                }}
              >
                G R A P H I C S
              </h2>

              <div className="gd-tech" style={{ display: disForGD }}>
                <Fade right cascade>
                  <p style={{ color: "rgb(46, 43, 43)" }}>
                    Head over to{" "}
                    <a
                      style={{ fontWeight: "500", color: "rgb(46, 43, 43)" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://intemtech.com/Portfolio.html"
                    >
                      <img
                        src={intemLogo}
                        alt=""
                        style={{ height: "20px", width: "20px" }}
                      />{" "}
                      INTEM - A Product by{" "}
                      <span style={{ color: "#9533BE" }}>
                        <img
                          src={sbLogo}
                          alt=""
                          style={{ height: "22px", width: "20px" }}
                        />{" "}
                        SenaryBits
                      </span>
                    </a>
                  </p>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioTech;

import React, { useState, useEffect } from "react";
import { useTransition, animated, config } from "react-spring";
import "./imgstyles.css";

const ImageDisplay = (props) => {
  //   console.log("ENTER ", props.slides, props.prjName);
  //   var slides;
  //   const dairypal = [
  //     {
  //       id: 0,
  //       url: process.env.PUBLIC_URL + `/assets/images/dairypal-web/main.png`,
  //     },
  //     {
  //       id: 1,
  //       url: process.env.PUBLIC_URL + `/assets/images/dairypal-web/login.png`,
  //     },
  //     {
  //       id: 2,
  //       url: process.env.PUBLIC_URL + `/assets/images/dairypal-web/home.png`,
  //     },
  //   ];

  //   const dairypalMob = [
  //     {
  //       id: 0,
  //       url: process.env.PUBLIC_URL + `/assets/images/dairypal-mob/main.png`,
  //     },
  //     {
  //       id: 1,
  //       url: process.env.PUBLIC_URL + `/assets/images/dairypal-mob/home.png`,
  //     },
  //     {
  //       id: 2,
  //       url: process.env.PUBLIC_URL + `/assets/images/dairypal-mob/store.png`,
  //     },
  //   ];

  //   const coignitive = [
  //     {
  //       id: 0,
  //       url: process.env.PUBLIC_URL + `/assets/images/coignitive-agent/main.png`,
  //     },
  //   ];

  //   const frostie = [
  //     {
  //       id: 0,
  //       url: process.env.PUBLIC_URL + `/assets/images/frostie-vr/main.png`,
  //     },
  //     {
  //       id: 1,
  //       url: process.env.PUBLIC_URL + `/assets/images/frostie-vr/two.png`,
  //     },
  //     {
  //       id: 2,
  //       url: process.env.PUBLIC_URL + `/assets/images/frostie-vr/three.png`,
  //     },
  //     {
  //       id: 3,
  //       url: process.env.PUBLIC_URL + `/assets/images/frostie-vr/four.png`,
  //     },
  //   ];

  //   const jozeko = [
  //     { id: 0, url: process.env.PUBLIC_URL + `/assets/images/jozeko/first.png` },
  //     { id: 1, url: process.env.PUBLIC_URL + `/assets/images/jozeko/two.png` },
  //     { id: 2, url: process.env.PUBLIC_URL + `/assets/images/jozeko/three.png` },
  //     { id: 3, url: process.env.PUBLIC_URL + `/assets/images/jozeko/four.png` },
  //   ];

  //   const aceso = [
  //     { id: 0, url: process.env.PUBLIC_URL + `/assets/images/aceso/first.png` },
  //     { id: 1, url: process.env.PUBLIC_URL + `/assets/images/aceso/second.png` },
  //     { id: 2, url: process.env.PUBLIC_URL + `/assets/images/aceso/third.png` },
  //     { id: 3, url: process.env.PUBLIC_URL + `/assets/images/aceso/fourth.png` },
  //   ];

  //   const broadband = [
  //     {
  //       id: 0,
  //       url: process.env.PUBLIC_URL + `/assets/images/broadband/main.png`,
  //     },
  //   ];

  //   if (props.slides === "dairypal") {
  //     slides = dairypal;
  //   } else if (props.slides === "dairypalMob") {
  //     slides = dairypalMob;
  //   } else if (props.slides === "broadband") {
  //     slides = broadband;
  //   } else if (props.slides === "aceso") {
  //     slides = aceso;
  //   } else if (props.slides === "coignitive") {
  //     slides = coignitive;
  //   } else if (props.slides === "frosite") {
  //     slides = frostie;
  //   } else if (props.slides === "jozeko") {
  //     slides = jozeko;
  //   }

  const [index, set] = useState(0);
  const transitions = useTransition(
    props.slides[index],
    (item) => item,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: config.molasses,
    },
    [props.slides]
  );

  useEffect(
    () => void setInterval(() => set((state) => (state + 1) % 4), 7000),
    []
  );

  return transitions.map(({ item, props, key }) => (
    <animated.div
      key={key}
      className="bg-mob"
      style={{
        ...props,
        backgroundImage: `url(${item})`,
      }}
    />
  ));
};

export default ImageDisplay;

import React, { useEffect, useState } from "react";
// import Navb from "../Musts/Navb";
import ScrollAnimation from "react-animate-on-scroll";
// import FooterLanding from "../LandingPage/FooterLanding";
import Title from "./Title";
// import ParticlesBg from "particles-bg";
import PortfolioIntro from "./PortfolioIntro";
import PortfolioServices from "./PortfolioServices";
import PortfolioTech from "./PortfolioTech";
import PortfolioProducts from "./PortfolioProducts";
// import PortfolioTeam from "./PortfolioTeam";
// import SideBar from "./SideBar";
// import Card from "./Card";

const PortfolioMain = () => {
  const [disForSideBar, setDisForSideBar] = useState("none");

  useEffect(() => {
    document.body.style.backgroundColor = "#f4f2fb";
    document.body.style.fontFamily = "Jost";
  });

  const myCB = (dataFromC) => {
    setDisForSideBar(dataFromC);
    console.log("Here III ", dataFromC, disForSideBar);
    //console.log("Hello checking state" + clr);
  };

  return (
    <>
      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
        {/* <div>LMAO</div> */}
        {/* <SideBar disForMain={disForSideBar} style={{ position: "absolute" }} /> */}
        <div style={{ marginTop: 0 }}>
          <Title />
        </div>
        <div>
          <PortfolioIntro />
        </div>
        <div>
          <PortfolioServices />
        </div>
        <div>
          <PortfolioTech />
        </div>
        <div>
          <PortfolioProducts callbackFromP={myCB} />
        </div>
        {/* <div>
          <PortfolioTeam />
        </div> */}
        {/* <div>
          <Card />
        </div> */}
        {/* <FooterLanding /> */}
      </ScrollAnimation>
    </>
  );
};

export default PortfolioMain;

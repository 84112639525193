import React from "react";
import { Zoom } from "react-reveal";

const PortfolioServices = () => {
  return (
    <>
      <div className="serve-main">
        <div className="serve-text">
          <Zoom left cascade>
            <div>
              <h2 className="serve-p">
                Throughout the beginning, we have expanded our offerings and
                capitalized on evolving technology trends to better serve our
                clients. We’ll use whatever technology makes the most sense for
                a particular project. Sometimes this decision is driven by
                budget, others by maintainability or sometimes by the technology
                you are already using. Bring your idea. Let us create it. Our
                team is well acquainted with the most popular technologies.
              </h2>
            </div>
          </Zoom>
          <div className="hr-serve-tag"></div>

          <h1 className="serve-h">
            <Zoom right cascade>
              S E R V I C E S
            </Zoom>
          </h1>
        </div>
      </div>
    </>
  );
};

export default PortfolioServices;

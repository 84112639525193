import React from "react";
// import Des from "../SVGs/Des";
// import ScrollAnimation from "react-animate-on-scroll";
import Writing from "../SVGs/Writing";
import "./Design.css";

const ContentWriting = () => {
  const svgPath = process.env.PUBLIC_URL + "/assets/svgs/Content/";

  return (
    <div>
      {/* <span>Technology & Development</span>
                <span>Designing</span>
                <span>Machine Learning</span>
                <span>Artificial Intelligene</span>
                <span>Writing</span> */}
      <div className="list-header"></div>
      <div
        style={{
          // border: "1px solid #b7acd8",
          borderRadius: "32px",
          padding: "20px",
          // backgroundColor: "#b7acd8",
        }}
      >
        <div className="main-div">
          <div className="heading animate__animated animate__fadeIn animate__slow">
            <Writing />
            <h5
              style={{
                fontWeight: "bold",
                color: "#5b1b76",
                marginTop: "10px",
              }}
            >
              Content Writing
            </h5>
          </div>
          <span style={{ width: "40px", height: "20px" }}></span>
          <hr className="resp-hr" />
          <hr className="hr-hor" />
          <div className="table-of-tech animate__animated  animate__zoomIn">
            {/* <ScrollAnimation
              delay={700}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}Advertising Content.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>
                Advertising Content
              </p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={600}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}Blog Writing 1.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Blog Writing</p>
            </div>
            {/* </ScrollAnimation> */}
            {/* <div className="padding-for-cards">
              <img alt=""
                 className="image-hw" 
                src={`${svgPath}illustrator-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Illustrator</p>
            </div> */}
            {/* <ScrollAnimation
              delay={500}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            {/* <div className="padding-for-cards">
              <img alt="" className="image-hw" src={`${svgPath}Blog Writing 2.svg`} />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>
                Blog Writing lol
              </p>
            </div> */}
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={400}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}InfoGraphics.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>
                Info Graphics
              </p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={300}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img alt="" className="image-hw" src={`${svgPath}SEO.svg`} />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>SEO</p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={200}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}
            <div className="padding-for-cards">
              <img
                alt=""
                className="image-hw"
                src={`${svgPath}Technical Writing.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>
                Technical Writing
              </p>
            </div>
            {/* </ScrollAnimation>
            <ScrollAnimation
              delay={100}
              animateIn="bounceInDown"
              animateOnce={true}
              // animateOut="bounceItUp"
            > */}

            {/* </ScrollAnimation> */}
            {/* <div className="padding-for-cards">
              <img alt=""
                 className="image-hw" 
                src={`${svgPath}node-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Node.js</p>
            </div>
            <div className="padding-for-cards">
              <img alt=""
                 className="image-hw" 
                src={`${svgPath}Django-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Django</p>
            </div>
            <div className="padding-for-cards">
              <img alt=""
                 className="image-hw" 
                src={`${svgPath}java-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>JAVA</p>
            </div>
            <div className="padding-for-cards">
              <img alt=""
                 className="image-hw" 
                src={`${svgPath}python-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>Python</p>
            </div>
            <div className="padding-for-cards">
              <img alt=""
                 className="image-hw" 
                src={`${svgPath}mongo-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>MongoDB</p>
            </div>
            <div className="padding-for-cards">
              <img alt=""
                 className="image-hw" 
                src={`${svgPath}sql-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>MySQL</p>
            </div>
            <div className="padding-for-cards">
              <img alt=""
                 className="image-hw" 
                src={`${svgPath}postgre-icon.svg`}
              />
              <p style={{ fontSize: "14px", marginTop: "5px" }}>PostgreSQL</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentWriting;
